﻿/**
    ACTION

    Container for action step buttons

    [1]. Clear floating buttons
*/

/*------------------------------------*\
    LOCAL VARIABLES
\*------------------------------------*/

$_action-pad: 20px;
$_action-border: 1px solid palette(layout, border);

/*------------------------------------*\
    STYLE
\*------------------------------------*/

.snip-actions {
    border-top: $_action-border;
    padding: $padding-small;

    // [1]
    @include clearfix;

    @media #{$mq-medium} {
        padding: $_action-pad;
    }
}

.snip-actions--no-border {
    border-top: none;
}

.snip-actions--top {
    border-top: none;
    border-bottom: $_action-border;
}

.snip-actions__link {
    color: palette(action, link);
}

.snip-actions__link--small {
    font-size: fontsize(action, small);
}

.snip-actions__link--right {
    float: right;
}