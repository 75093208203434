@import "_variables.scss";

@import "theme/global.scss";
@import "theme/structure.scss";
@import "vendor/selectem.scss";
// @import "vendor/slick.scss";
@import "theme/lifestyle.scss";

@import "vendor/icomoon.scss";
@import "vendor/jquery-ui-slider.scss";
@import "vendor/jquery-ui-slider-pips.scss";
@import "vendor/animate.scss";
@import "vendor/bites-theme.scss";

/* Custom Akia CSS */
@import "_modals.scss";
@import "_custom.scss";
@import "_plans.scss";

/* Snipcart CSS */
@import "snipcart/snipcart.scss"