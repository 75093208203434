/*!
 * Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 */
div[id*=bite] *,
div[id*=bite] *::before,
div[id*=bite] *::after {box-sizing: border-box}
@-ms-viewport {width: device-width}
div[id*=bite] [tabindex="-1"]:focus {outline: 0 !important}
div[id*=bite] hr {box-sizing: content-box;height: 0;overflow: visible}
div[id*=bite] h1,
div[id*=bite] h2,
div[id*=bite] h3,
div[id*=bite] h4,
div[id*=bite] h5,
div[id*=bite] h6 {margin-top:0;margin-bottom: 8px}
div[id*=bite] p {margin-top: 0;margin-bottom: 16px}
div[id*=bite] ol,
div[id*=bite] ul,
div[id*=bite] dl {margin-top: 0;margin-bottom: 16px}
div[id*=bite] ol ol,
div[id*=bite] ul ul,
div[id*=bite] ol ul,
div[id*=bite] ul ol {margin-bottom: 0}
div[id*=bite] dt {font-weight: 700}
div[id*=bite] dd {margin-bottom: 8px;margin-left: 0}
div[id*=bite] blockquote {margin: 0 0 16px}
div[id*=bite] b,
div[id*=bite] strong {font-weight: bolder}
div[id*=bite] small {font-size: 80%}
div[id*=bite] sub,
div[id*=bite] sup {position: relative;font-size: 75%;line-height: 0;vertical-align: baseline}
div[id*=bite] sub {bottom: -.25em}
div[id*=bite] up {top: -.5em}
div[id*=bite] a {color: #007bff;text-decoration: none;background-color: transparent}
div[id*=bite] a:hover {color: #0056b3;text-decoration: underline}
div[id*=bite] a:not([href]):not([tabindex]) {color: inherit;text-decoration: none}
div[id*=bite] a:not([href]):not([tabindex]):hover,
div[id*=bite] a:not([href]):not([tabindex]):focus {color:inherit;text-decoration: none}
div[id*=bite] a:not([href]):not([tabindex]):focus {outline: 0}
div[id*=bite] pre {margin-top: 0;margin-bottom: 16px;overflow: auto;-ms-overflow-style: scrollbar}
div[id*=bite] figure {margin: 0 0 16px}
div[id*=bite] mg {vertical-align: middle;border-style: none}
div[id*=bite] svg {overflow: hidden;vertical-align: middle}
div[id*=bite] table {border-collapse: collapse}
div[id*=bite] caption {padding-top: 12px;padding-bottom: 12px;color: #6c757d;text-align: left;caption-side: bottom}
div[id*=bite] th {text-align: inherit}
div[id*=bite] label {display: inline-block;margin-bottom: 8px}
div[id*=bite] button {border-radius: 0}
div[id*=bite] button:focus {outline: 1px dotted;outline: 5px auto -webkit-focus-ring-color}
div[id*=bite] input,
div[id*=bite] button,
div[id*=bite] select,
div[id*=bite] optgroup,
div[id*=bite] textarea {margin: 0;font-family: inherit;font-size: inherit;line-height: inherit}
div[id*=bite] button,
div[id*=bite] input {overflow: visible}
div[id*=bite] button,
div[id*=bite] select {text-transform: none}
div[id*=bite] button,
div[id*=bite] [type="button"],
div[id*=bite] [type="reset"],
div[id*=bite] [type="submit"] {-webkit-appearance: button}
div[id*=bite] button:focus,
div[id*=bite] [type="button"]:focus,
div[id*=bite] [type="reset"]:focus,
div[id*=bite] [type="submit"]:focus {outline: 0;}
div[id*=bite] button::-moz-focus-inner,
div[id*=bite] [type="button"]::-moz-focus-inner,
div[id*=bite] [type="reset"]::-moz-focus-inner,
div[id*=bite] [type="submit"]::-moz-focus-inner {padding: 0;border-style: none}
div[id*=bite] input[type="radio"],
div[id*=bite] input[type="checkbox"] {box-sizing: border-box;padding: 0}
div[id*=bite] input[type="date"],
div[id*=bite] input[type="time"],
div[id*=bite] input[type="datetime-local"],
div[id*=bite] input[type="month"] {-webkit-appearance: listbox}
div[id*=bite] textarea {overflow: auto;resize: vertical}
div[id*=bite] fieldset {min-width: 0;padding: 0;margin: 0;border: 0}
div[id*=bite] [type="number"]::-webkit-inner-spin-button,
div[id*=bite] [type="number"]::-webkit-outer-spin-button {height: auto}
div[id*=bite] [type="search"] {outline-offset: -2px;-webkit-appearance: none}
div[id*=bite] [type="search"]::-webkit-search-decoration {-webkit-appearance: none}
div[id*=bite] ::-webkit-file-upload-button {font: inherit;-webkit-appearance: button}
div[id*=bite] output {display: inline-block}
div[id*=bite] summary {display: list-item;cursor: pointer}
div[id*=bite] template {display: none}
div[id*=bite] [hidden] {display: none !important}
/*# sourceMappingURL=bootstrap-reboot.css.map */

.modal-open {overflow: hidden}
.modal-open div[id*=bite].modal {overflow-x: hidden;overflow-y: auto;}
div[id*=bite].modal {font-family: 'Nunito Sans', sans-serif;font-weight: 300;letter-spacing: normal;position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 10000;display: none;overflow: hidden;outline: 0;}
div[id*=bite].fade {opacity: 0;transition: opacity .15s linear}
div[id*=bite].modal.fade {opacity:1}
div[id*=bite].fade.show {opacity:1}
div[id*=bite] .modal-dialog {position: relative;width: auto;margin: 8px;pointer-events: none;}
div[id*=bite].modal.fade .modal-dialog {transition: -webkit-transform .3s ease-out;transition: transform .3s ease-out;transition: transform .3s ease-out,-webkit-transform .3s ease-out;-webkit-transform: translate(0,0);transform: translate(0,0)}
div[id*=bite].modal.show .modal-dialog {-webkit-transform: translate(0,0);transform: translate(0,0)}

.modal-backdrop {position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 1040 !important;background-color: #000}
.modal-backdrop.fade {opacity: 0}
.modal-backdrop.show {opacity: .5}
.modal-backdrop.in {filter: alpha(opacity=50);opacity: .5}

div[id*=bite] .modal-content,
div[id*=bite] .modal-header,
div[id*=bite] .modal-body,
div[id*=bite] .modal-footer {border: 0;padding: 0;border-radius: 0}
div[id*=bite] .modal-content {position: relative;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;width: 100%;pointer-events: auto;background-color: #fff;background-clip: padding-box;border-radius: 10px;outline: 0}
div[id*=bite] .modal-header {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between}
div[id*=bite] .modal-body {position: relative;-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto}
div[id*=bite] .modal-footer {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end}
div[id*=bite] .modal-footer>:not(:first-child) {margin-left: 4px}
div[id*=bite] .modal-footer>:not(:last-child) {margin-right: 4px}

div[id*=bite] .close {float: right;font-size: 24px;font-weight: 700;line-height: 1;color: #000;text-shadow: 0 1px 0 #fff}
div[id*=bite] button.close {background-color: transparent;border: 0;-webkit-appearance: none}
div[id*=bite] .close:not(:disabled):not(.disabled) {cursor: pointer}
div[id*=bite] .modal-header .close {padding: 20px;margin: 0;position: absolute;top: 6px;right: 8px;opacity: 1;outline: 0;z-index: 2}
div[id*=bite] .modal-header .close:before {content: '';width: 15px;height: 15px;position: absolute;top: 12.5px;right: 12.5px;background-size: contain;opacity: .3;-webkit-transition: opacity 0.3s ease-in-out;-moz-transition: opacity 0.3s ease-in-out;-ms-transition: opacity 0.3s ease-in-out;-o-transition: opacity 0.3s ease-in-out;transition: opacity 0.3s ease-in-out}
div[id*=bite] .modal-header .close:hover:before {opacity: 1}
div[id*=bite] .close.close-light:before {background-image: url(../images/Icon-Close-Light.png);opacity: .6}
div[id*=bite] .close.close-dark:before {background-image: url(../images/Icon-Close-Dark.png)}

/* --- bite text --- */
div[id*=bite] strong {font-weight: 600}
div[id*=bite] .popup-text {opacity: 0.8;color: #0f0f0f}

div[id*=bite] .text-center {text-align: center!important}
div[id*=bite] .text-left {text-align: left!important}
div[id*=bite] .text-right {text-align: right!important}

div[id*=bite] .text-white {color: #fff!important}
div[id*=bite] .text-light {color: #f8f9fa!important}

div[id*=bite] .font-weight-light {font-weight: 300!important}
div[id*=bite] .font-weight-normal {font-weight: normal!important}
div[id*=bite] .font-weight-bold {font-weight: 700!important}
/* --- /bite text --- */

/* --- bite form --- */
div[id*=bite] .form-control::-webkit-input-placeholder {opacity: 1; transition: opacity 0.3s ease}
div[id*=bite] .form-control::-moz-placeholder {opacity: 1; transition: opacity 0.3s ease}
div[id*=bite] .form-control:-moz-placeholder {opacity: 1; transition: opacity 0.3s ease}
div[id*=bite] .form-control:-ms-input-placeholder {opacity: 1; transition: opacity 0.3s ease}

div[id*=bite] .form-control:focus::-webkit-input-placeholder {opacity: 0}
div[id*=bite] .form-control:focus::-moz-placeholder {opacity: 0}
div[id*=bite] .form-control:focus:-moz-placeholder {opacity: 0}
div[id*=bite] .form-control:focus:-ms-input-placeholder {opacity: 0}

div[id*=bite] .form-control::-webkit-input-placeholder {font-family: inherit;font-weight: normal;font-size: 14px;line-height: 1.6;color: rgba(0,0,0,.5) !important}
div[id*=bite] .form-control::-moz-placeholder {font-family: inherit;font-weight: normal;font-size: 14px;line-height: 2;color: rgba(0,0,0,.5) !important}
div[id*=bite] .form-control:-moz-placeholder {font-family: inherit;font-weight: normal;font-size: 14px;line-height: 2;color: rgba(0,0,0,.5) !important}
div[id*=bite] .form-control:-ms-input-placeholder {font-family: inherit;font-weight: normal;font-size: 14px;line-height: 1.6;color: rgba(0,0,0,.5) !important}

div[id*=bite] .form-control.text-light::-webkit-input-placeholder {color: rgba(226,226,226,.6) !important}
div[id*=bite] .form-control.text-light::-moz-placeholder {color: rgba(226,226,226,.6) !important}
div[id*=bite] .form-control.text-light:-moz-placeholder {color: rgba(226,226,226,.6) !important}
div[id*=bite] .form-control.text-light:-ms-input-placeholder {color: rgba(226,226,226,.6) !important}

div[id*=bite] textarea.form-control::-webkit-input-placeholder {line-height: 1.3}
div[id*=bite] textarea.form-control::-moz-placeholder {line-height: 1.3}
div[id*=bite] textarea.form-control:-moz-placeholder {line-height: 1.3}
div[id*=bite] textarea.form-control:-ms-input-placeholder {line-height: 1.3}

div[id*=bite] textarea {resize: none}
div[id*=bite] .form-inline {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row wrap;flex-flow: row wrap;-webkit-box-align: center;-ms-flex-align: center;align-items: center}
div[id*=bite] .form-group {margin-bottom: 16px}
div[id*=bite] .form-control {display: block;width: 100%;padding: 6px 12px;font-size: 16px;line-height: 1.4;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;border-radius: 4px;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;box-shadow: none}
div[id*=bite] .form-inline .form-control {border: 0}
div[id*=bite] .form-control.focus-light:focus {border-color:#fff;box-shadow:0 0 0 3px rgba(255,255,255,.25)}

div[id*=bite] .input-group {position: relative;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-align: stretch;-ms-flex-align: stretch;align-items: stretch;width: 100%}
div[id*=bite] .input-group-append, 
div[id*=bite] .input-group-prepend {display: -webkit-box;display: -ms-flexbox;display: flex;}
div[id*=bite] .input-group-prepend {margin-right: -1px;}
div[id*=bite] .input-group-text {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;padding: 6px 12px;margin-bottom: 0;font-size: 16px;font-weight: 400;line-height: 1.5;color: #495057;text-align: center;white-space: nowrap;background-color: transparent;border: 1px solid #ced4da;border-radius: 4px}
div[id*=bite] .input-group-text i {color: rgba(40,47,54,.3);font-size: 18px}
div[id*=bite] .input-group>.custom-file, 
div[id*=bite] .input-group>.custom-select, 
div[id*=bite] .input-group>.form-control {position: relative;-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;width: 1%; margin-bottom: 0}
div[id*=bite] .input-group-addon {width: auto;right: 0;padding: 10px 12px;z-index: 99;background-color: transparent;border: 0}
div[id*=bite] .input-group-addon a {line-height: 25px}
div[id*=bite] .input-group-addon i {color: rgba(40,47,54,.3);font-size: 18px}

div[id*=bite] .form-check {padding-left: 32px;position: relative;display: block}
div[id*=bite] .form-check-input {margin-top: 4px;margin-left: -32px;margin-bottom: 0;position: absolute;}
div[id*=bite] .form-check-label {font-size: 14px;font-weight: 300; line-height: 1.86;color: rgba(40,47,54,.8)}
div[id*=bite] .form-check .form-check-label:after {content: '';display: table;clear: both}
div[id*=bite] .form-check .cr {position: absolute;left: 0;display: inline-block;border: 1px solid #a9a9a9;border-radius: 4px;width: 18px;height: 18px;margin-right: 8px;margin-top: 3px;}
div[id*=bite] .form-check .cr .cr-icon {position: absolute;font-size: 12px;line-height: 0;top: 50%;left: 15%}
div[id*=bite] .form-check .form-check-label input[type="checkbox"] {display: none}
div[id*=bite] .form-check .form-check-label input[type="checkbox"]+.cr>.cr-icon {opacity: 0}
div[id*=bite] .form-check .form-check-label input[type="checkbox"]:checked+.cr>.cr-icon {opacity: 1}
div[id*=bite] .form-check .form-check-label input[type="checkbox"]:disabled+.cr {opacity: .5}
/* --- /bite form --- */

/* --- bite button --- */
div[id*=bite] .btn {display: inline-block;font-weight: 400;text-align: center;white-space: nowrap;vertical-align: middle;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;padding: 6px 12px;font-size: 16px;line-height: 1.5;border: 0;border-radius: 4px;transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out}
div[id*=bite] .btn:focus {border: 0;box-shadow: none}
div[id*=bite] .btn-link {font-weight: 400;color: #007bff;background-color: transparent}
div[id*=bite] .btn-block {display: block;width: 100%}

div[id*=bite] a {-webkit-transition: all 0.2s ease-in-out;-moz-transition: all 0.2s ease-in-out;-ms-transition: all 0.2s ease-in-out;-o-transition: all 0.2s ease-in-out;transition: all 0.2s ease-in-out;text-decoration: none !important;}
/* --- /bite button --- */

div[id*=bite] .border-0 { border: 0 !important; }
div[id*=bite] .border-top-0 { border-top: 0 !important; }
div[id*=bite] .border-right-0 { border-right: 0 !important; }
div[id*=bite] .border-bottom-0 { border-bottom: 0 !important; }
div[id*=bite] .border-left-0 { border-left: 0 !important; }

/* --- bite border radius --- */
div[id*=bite] .b-radius-25 {border-radius: 25px !important}
div[id*=bite] .b-radius-30 {border-radius: 30px !important}

div[id*=bite] .rounded {border-radius: 4px!important}
div[id*=bite] .rounded-top {border-top-left-radius: 4px!important;border-top-right-radius: 4px!important}
div[id*=bite] .rounded-right {border-top-right-radius: 4px!important;border-bottom-right-radius: 4px!important}
div[id*=bite] .rounded-bottom {border-bottom-right-radius: 4px!important;border-bottom-left-radius: 4px!important}
div[id*=bite] .rounded-left {border-top-left-radius: 4px!important;border-bottom-left-radius: 4px!important}
div[id*=bite] .rounded-circle {border-radius: 50% !important}
div[id*=bite] .rounded-0 {border-radius: 0 !important}

div[id*=bite] .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle), 
div[id*=bite] .input-group>.input-group-append:last-child>.input-group-text:not(:last-child), 
div[id*=bite] .input-group>.input-group-append:not(:last-child)>.btn, 
div[id*=bite] .input-group>.input-group-append:not(:last-child)>.input-group-text, 
div[id*=bite] .input-group>.input-group-prepend>.btn, 
div[id*=bite] .input-group>.input-group-prepend>.input-group-text {border-top-right-radius: 0;border-bottom-right-radius: 0}

div[id*=bite] .input-group>.custom-select:not(:first-child), 
div[id*=bite] .input-group>.form-control:not(:first-child) {border-top-left-radius: 0;border-bottom-left-radius: 0}
/* --- /bite border radius --- */

div[id*=bite] .popup-title {color: #0f0f0f;font-weight: 700}

div[id*=bite] .popup-bg-image {height: 0;width: 0;overflow: hidden}

/* --- bite sizes --- */
div[id*=bite] .wh {height:100%!important}
div[id*=bite] .ww {width:100%!important}
/* --- /bite sizes --- */

/* --- bite grid --- */
div[id*=bite] .col, 
div[id*=bite] .col-1, 
div[id*=bite] .col-10, 
div[id*=bite] .col-11, 
div[id*=bite] .col-12, 
div[id*=bite] .col-2, 
div[id*=bite] .col-3, 
div[id*=bite] .col-4, 
div[id*=bite] .col-5, 
div[id*=bite] .col-6, 
div[id*=bite] .col-7, 
div[id*=bite] .col-8, 
div[id*=bite] .col-9, 
div[id*=bite] .col-auto, 
div[id*=bite] .col-lg, 
div[id*=bite] .col-lg-1, 
div[id*=bite] .col-lg-10, 
div[id*=bite] .col-lg-11, 
div[id*=bite] .col-lg-12, 
div[id*=bite] .col-lg-2, 
div[id*=bite] .col-lg-3, 
div[id*=bite] .col-lg-4, 
div[id*=bite] .col-lg-5, 
div[id*=bite] .col-lg-6, 
div[id*=bite] .col-lg-7, 
div[id*=bite] .col-lg-8, 
div[id*=bite] .col-lg-9, 
div[id*=bite] .col-lg-auto, 
div[id*=bite] .col-md, 
div[id*=bite] .col-md-1, 
div[id*=bite] .col-md-10, 
div[id*=bite] .col-md-11, 
div[id*=bite] .col-md-12, 
div[id*=bite] .col-md-2, 
div[id*=bite] .col-md-3, 
div[id*=bite] .col-md-4, 
div[id*=bite] .col-md-5, 
div[id*=bite] .col-md-6, 
div[id*=bite] .col-md-7, 
div[id*=bite] .col-md-8, 
div[id*=bite] .col-md-9, 
div[id*=bite] .col-md-auto, 
div[id*=bite] .col-sm, 
div[id*=bite] .col-sm-1, 
div[id*=bite] .col-sm-10, 
div[id*=bite] .col-sm-11, 
div[id*=bite] .col-sm-12, 
div[id*=bite] .col-sm-2, 
div[id*=bite] .col-sm-3, 
div[id*=bite] .col-sm-4, 
div[id*=bite] .col-sm-5, 
div[id*=bite] .col-sm-6, 
div[id*=bite] .col-sm-7, 
div[id*=bite] .col-sm-8, 
div[id*=bite] .col-sm-9, 
div[id*=bite] .col-sm-auto, 
div[id*=bite] .col-xl, 
div[id*=bite] .col-xl-1, 
div[id*=bite] .col-xl-10, 
div[id*=bite] .col-xl-11, 
div[id*=bite] .col-xl-12, 
div[id*=bite] .col-xl-2, 
div[id*=bite] .col-xl-3, 
div[id*=bite] .col-xl-4, 
div[id*=bite] .col-xl-5, 
div[id*=bite] .col-xl-6, 
div[id*=bite] .col-xl-7, 
div[id*=bite] .col-xl-8, 
div[id*=bite] .col-xl-9, 
div[id*=bite] .col-xl-auto {position: relative;width: 100%;min-height: 1px;padding-right: 15px;padding-left: 15px}

div[id*=bite] .row {display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;margin-right: -15px;margin-left: -15px}

div[id*=bite] .col {-ms-flex-preferred-size: 0;flex-basis: 0;-webkit-box-flex: 1;-ms-flex-positive: 1;flex-grow: 1;max-width: 100%}

div[id*=bite] .order-first {-webkit-box-ordinal-group: 0;-ms-flex-order: -1;order: -1}
div[id*=bite] .order-last {-webkit-box-ordinal-group: 14;-ms-flex-order: 13;order: 13}
/* --- /bite grid --- */

/* --- bite spacing --- */
div[id*=bite] .m-0 {margin: 0!important}

div[id*=bite] .mr-0, div[id*=bite] .mx-0 {margin-right: 0!important}
div[id*=bite] .ml-0, div[id*=bite] .mx-0 {margin-left: 0!important}
div[id*=bite] .mt-0, div[id*=bite] .my-0 {margin-top: 0!important}
div[id*=bite] .mb-0, div[id*=bite] .my-0 {margin-bottom: 0!important}

div[id*=bite] .mr-1, div[id*=bite] .mx-1 {margin-right: 4px!important}
div[id*=bite] .ml-1, div[id*=bite] .mx-1 {margin-left: 4px!important}
div[id*=bite] .mt-1, div[id*=bite] .my-1 {margin-top: 4px!important}
div[id*=bite] .mb-1, div[id*=bite] .my-1 {margin-bottom: 4px!important}

div[id*=bite] .mr-2, div[id*=bite] .mx-2 {margin-right: 8px!important}
div[id*=bite] .ml-2, div[id*=bite] .mx-2 {margin-left: 8px!important}
div[id*=bite] .mt-2, div[id*=bite] .my-2 {margin-top: 8px!important}
div[id*=bite] .mb-2, div[id*=bite] .my-2 {margin-bottom: 8px!important}

div[id*=bite] .mr-3, div[id*=bite] .mx-3 {margin-right: 16px!important}
div[id*=bite] .ml-3, div[id*=bite] .mx-3 {margin-left: 16px!important}
div[id*=bite] .mt-3, div[id*=bite] .my-3 {margin-top: 16px!important}
div[id*=bite] .mb-3, div[id*=bite] .my-3 {margin-bottom: 16px!important}

div[id*=bite] .mr-4, div[id*=bite] .mx-4 {margin-right: 24px!important}
div[id*=bite] .ml-4, div[id*=bite] .mx-4 {margin-left: 24px!important}
div[id*=bite] .mt-4, div[id*=bite] .my-4 {margin-top: 24px!important}
div[id*=bite] .mb-4, div[id*=bite] .my-4 {margin-bottom: 24px!important}

div[id*=bite] .mr-5, div[id*=bite] .mx-5 {margin-right: 48px!important}
div[id*=bite] .ml-5, div[id*=bite] .mx-5 {margin-left: 48px!important}
div[id*=bite] .mt-5, div[id*=bite] .my-5 {margin-top: 48px!important}
div[id*=bite] .mb-5, div[id*=bite] .my-5 {margin-bottom: 48px!important}

div[id*=bite] .p-0 {padding: 0!important}

div[id*=bite] .pr-0, div[id*=bite] .px-0 {padding-right: 0!important}
div[id*=bite] .pl-0, div[id*=bite] .px-0 {padding-left: 0!important}
div[id*=bite] .pt-0, div[id*=bite] .py-0 {padding-top: 0!important}
div[id*=bite] .pb-0, div[id*=bite] .py-0 {padding-bottom: 0!important}

div[id*=bite] .pr-1, div[id*=bite] .px-1 {padding-right: 4px!important}
div[id*=bite] .pl-1, div[id*=bite] .px-1 {padding-left: 4px!important}
div[id*=bite] .pt-1, div[id*=bite] .py-1 {padding-top: 4px!important}
div[id*=bite] .pb-1, div[id*=bite] .py-1 {padding-bottom: 4px!important}

div[id*=bite] .pr-2, div[id*=bite] .px-2 {padding-right: 8px!important}
div[id*=bite] .pl-2, div[id*=bite] .px-2 {padding-left: 8px!important}
div[id*=bite] .pt-2, div[id*=bite] .py-2 {padding-top: 8px!important}
div[id*=bite] .pb-2, div[id*=bite] .py-2 {padding-bottom: 8px!important}

div[id*=bite] .pr-3, div[id*=bite] .px-3 {padding-right: 16px!important}
div[id*=bite] .pl-3, div[id*=bite] .px-3 {padding-left: 16px!important}
div[id*=bite] .pt-3, div[id*=bite] .py-3 {padding-top: 16px!important}
div[id*=bite] .pb-3, div[id*=bite] .py-3 {padding-bottom: 16px!important}

div[id*=bite] .pr-4, div[id*=bite] .px-4 {padding-right: 24px!important}
div[id*=bite] .pl-4, div[id*=bite] .px-4 {padding-left: 24px!important}
div[id*=bite] .pt-4, div[id*=bite] .py-4 {padding-top: 24px!important}
div[id*=bite] .pb-4, div[id*=bite] .py-4 {padding-bottom: 24px!important}

div[id*=bite] .pr-5, div[id*=bite] .px-5 {padding-right: 48px!important}
div[id*=bite] .pl-5, div[id*=bite] .px-5 {padding-left: 48px!important}
div[id*=bite] .pt-5, div[id*=bite] .py-5 {padding-top: 48px!important}
div[id*=bite] .pb-5, div[id*=bite] .py-5 {padding-bottom: 48px!important}
/* --- /bite spacing --- */

/* --- bite background --- */
div[id*=bite] .bg-white {background-color: #fff!important;}
/* --- /bite background --- */

/* --- bite display --- */
div[id*=bite] .d-none {display: none !important}
div[id*=bite] .d-inline {display: inline !important}
div[id*=bite] .d-inline-block {display: inline-block !important}
div[id*=bite] .d-block {display: block !important}
div[id*=bite] .d-table {display: table !important}
div[id*=bite] .d-table-row {display: table-row !important}
div[id*=bite] .d-table-cell {display: table-cell !important}
div[id*=bite] .d-flex {display: flex !important}
div[id*=bite] .d-inline-flex {display: inline-flex !important}
/* --- /bite display --- */

/* --- bite position --- */
div[id*=bite] .position-relative {position: relative!important}
div[id*=bite] .position-absolute {position: absolute!important}
div[id*=bite] .position-static {position: static!important}
div[id*=bite] .position-fixed {position: fixed!important}
/* --- /bite position --- */

/* --- bite social --- */
div[id*=bite] .soc-login a {width: 33px;height: 33px;color: #fff;line-height: 35px}
div[id*=bite] .soc-login a i {font-size: 16px}
div[id*=bite] .soc-facebook {background-color: #2553b4}
div[id*=bite] .soc-facebook:hover {background-color: #366ad6}
div[id*=bite] .soc-facebook:active {background-color: #1c3f8a} 
div[id*=bite] .soc-twitter {background-color: #1da1f2}
div[id*=bite] .soc-twitter:hover {background-color: #4db5f5}
div[id*=bite] .soc-twitter:active {background-color: #0c85d0}
div[id*=bite] .soc-linkedin {background-color: #00a0dc}
div[id*=bite] .soc-linkedin:hover {background-color: #10beff}
div[id*=bite] .soc-linkedin:active {background-color: #007ba9}
/* --- /bite social --- */

/* --- bite flex --- */
div[id*=bite] .justify-content-start {-webkit-box-pack: start!important;-ms-flex-pack: start!important;justify-content: flex-start!important}
div[id*=bite] .justify-content-center {-webkit-box-pack: center!important;-ms-flex-pack: center!important;justify-content: center!important}
div[id*=bite] .justify-content-end {-webkit-box-pack: end!important;-ms-flex-pack: end!important;justify-content: flex-end!important}
div[id*=bite] .justify-content-between {-webkit-box-pack: justify!important;-ms-flex-pack: justify!important;justify-content: space-between!important}
div[id*=bite] .justify-content-around {-ms-flex-pack: distribute!important;justify-content: space-around!important}

div[id*=bite] .align-self-auto {-ms-flex-item-align: auto!important;align-self: auto!important}
div[id*=bite] .align-self-start {-ms-flex-item-align: start!important;align-self: flex-start!important}
div[id*=bite] .align-self-center {-ms-flex-item-align: center!important;align-self: center!important}
div[id*=bite] .align-self-end {-ms-flex-item-align: end!important;align-self: flex-end!important}
div[id*=bite] .align-self-baseline {-ms-flex-item-align: baseline!important;align-self: baseline!important}
div[id*=bite] .align-self-stretch {-ms-flex-item-align: stretch!important;align-self: stretch!important}

div[id*=bite] .align-content-start {-ms-flex-line-pack: start!important;align-content: flex-start!important}
div[id*=bite] .align-content-end {-ms-flex-line-pack: end!important;align-content: flex-end!important}
div[id*=bite] .align-content-center {-ms-flex-line-pack: center!important;align-content: center!important}
div[id*=bite] .align-content-between {-ms-flex-line-pack: justify!important;align-content: space-between!important}
div[id*=bite] .align-content-around {-ms-flex-line-pack: distribute!important;align-content: space-around!important}
div[id*=bite] .align-content-stretch {-ms-flex-line-pack: stretch!important;align-content: stretch!important}

div[id*=bite] .align-items-start {-webkit-box-align: start!important;-ms-flex-align: start!important;align-items: flex-start!important}
div[id*=bite] .align-items-center {-webkit-box-align: center!important;-ms-flex-align: center!important;align-items: center!important}
div[id*=bite] .align-items-end {-webkit-box-align: end!important;-ms-flex-align: end!important;align-items: flex-end!important}
div[id*=bite] .align-items-stretch {-webkit-box-align: stretch!important;-ms-flex-align: stretch!important;align-items: stretch!important}
div[id*=bite] .align-items-baseline {-webkit-box-align: baseline!important;-ms-flex-align: baseline!important;align-items: baseline!important}
/* --- /bite flex --- */

@media 
(-webkit-min-device-pixel-ratio: 2), 
(min-resolution: 192dpi) { 
    div[id*=bite] .close.close-light:before {background-image: url(../images/Icon-Close-Light@2x.png)}
    div[id*=bite] .close.close-dark:before {background-image: url(../images/Icon-Close-Dark@2x.png)}
}
@media (min-width: 576px) {
    div[id*=bite].m-width-870 .modal-dialog {max-width: 870px}
    div[id*=bite].m-width-770 .modal-dialog {max-width: 770px}
    div[id*=bite].m-width-670 .modal-dialog {max-width: 670px}
    div[id*=bite].m-width-640 .modal-dialog {max-width: 640px}
    div[id*=bite].m-width-570 .modal-dialog {max-width: 570px}
    div[id*=bite].m-width-500 .modal-dialog {max-width: 500px}
    div[id*=bite].m-width-470 .modal-dialog {max-width: 470px}
    div[id*=bite].m-width-370 .modal-dialog {max-width: 370px}
    div[id*=bite].m-width-330 .modal-dialog {max-width: 330px}
    div[id*=bite].m-width-300 .modal-dialog {max-width: 300px}
    div[id*=bite].m-width-270 .modal-dialog {max-width: 270px}
    
    div[id*=bite] .modal-dialog {max-width: 500px;margin: 28px auto;}
    
    div[id*=bite] .form-inline .form-group {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row wrap;flex-flow: row wrap;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-bottom: 0}
    div[id*=bite] .form-inline .form-control {display: inline-block;width: auto;vertical-align: middle}
    
    div[id*=bite] .col-sm-auto {-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;width: auto;max-width: none}
    
    div[id*=bite] .col-sm-1 {-webkit-box-flex: 0;-ms-flex: 0 0 8.333333%;flex: 0 0 8.333333%;max-width: 8.333333%}
    div[id*=bite] .col-sm-2 {-webkit-box-flex: 0;-ms-flex: 0 0 16.666667%;flex: 0 0 16.666667%;max-width: 16.666667%}
    div[id*=bite] .col-sm-3 {-webkit-box-flex: 0;-ms-flex: 0 0 25%;flex: 0 0 25%;max-width: 25%}
    div[id*=bite] .col-sm-4 {-webkit-box-flex: 0;-ms-flex: 0 0 33.333333%;flex: 0 0 33.333333%;max-width: 33.333333%}
    div[id*=bite] .col-sm-5 {-webkit-box-flex: 0;-ms-flex: 0 0 41.666667%;flex: 0 0 41.666667%;max-width: 41.666667%}
    div[id*=bite] .col-sm-6 {-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;max-width: 50%}
    div[id*=bite] .col-sm-7 {-webkit-box-flex: 0;-ms-flex: 0 0 58.333333%;flex: 0 0 58.333333%;max-width: 58.333333%}
    div[id*=bite] .col-sm-8 {-webkit-box-flex: 0;-ms-flex: 0 0 66.666667%;flex: 0 0 66.666667%;max-width: 66.666667%}
    div[id*=bite] .col-sm-9 {-webkit-box-flex: 0;-ms-flex: 0 0 75%;flex: 0 0 75%;max-width: 75%}
    div[id*=bite] .col-sm-10 {-webkit-box-flex: 0;-ms-flex: 0 0 83.333333%;flex: 0 0 83.333333%;max-width: 83.333333%}
    div[id*=bite] .col-sm-11 {-webkit-box-flex: 0;-ms-flex: 0 0 91.666667%;flex: 0 0 91.666667%;max-width: 91.666667%}
    div[id*=bite] .col-sm-12 {-webkit-box-flex: 0;-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%}
}
@media (min-width: 768px) {
    div[id*=bite] .col-md-auto {-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;width: auto;max-width: none}
    
    div[id*=bite] .col-md-1 {-webkit-box-flex: 0;-ms-flex: 0 0 8.333333%;flex: 0 0 8.333333%;max-width: 8.333333%}
    div[id*=bite] .col-md-2 {-webkit-box-flex: 0;-ms-flex: 0 0 16.666667%;flex: 0 0 16.666667%;max-width: 16.666667%}
    div[id*=bite] .col-md-3 {-webkit-box-flex: 0;-ms-flex: 0 0 25%;flex: 0 0 25%;max-width: 25%}
    div[id*=bite] .col-md-4 {-webkit-box-flex: 0;-ms-flex: 0 0 33.333333%;flex: 0 0 33.333333%;max-width: 33.333333%}
    div[id*=bite] .col-md-5 {-webkit-box-flex: 0;-ms-flex: 0 0 41.666667%;flex: 0 0 41.666667%;max-width: 41.666667%}
    div[id*=bite] .col-md-6 {-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;max-width: 50%}
    div[id*=bite] .col-md-7 {-webkit-box-flex: 0;-ms-flex: 0 0 58.333333%;flex: 0 0 58.333333%;max-width: 58.333333%}
    div[id*=bite] .col-md-8 {-webkit-box-flex: 0;-ms-flex: 0 0 66.666667%;flex: 0 0 66.666667%;max-width: 66.666667%}
    div[id*=bite] .col-md-9 {-webkit-box-flex: 0;-ms-flex: 0 0 75%;flex: 0 0 75%;max-width: 75%}
    div[id*=bite] .col-md-10 {-webkit-box-flex: 0;-ms-flex: 0 0 83.333333%;flex: 0 0 83.333333%;max-width: 83.333333%}
    div[id*=bite] .col-md-11 {-webkit-box-flex: 0;-ms-flex: 0 0 91.666667%;flex: 0 0 91.666667%;max-width: 91.666667%}
    div[id*=bite] .col-md-12 {-webkit-box-flex: 0;-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%}
}
@media only screen and (min-width: 992px) {
    div[id*=bite] .no-padding {padding-left: 0;padding-right: 0}
    div[id*=bite] .no-padding-left {padding-left: 0}
    div[id*=bite] .no-padding-right {padding-right: 0}
    
    .modal-open div[id*=bite].modal.position-bottom-center,
    .modal-open div[id*=bite].modal.position-bottom-left,
    .modal-open div[id*=bite].modal.position-bottom-right {overflow-y: hidden}
    
    div[id*=bite].modal.position-top-center .modal-dialog {margin: 28px auto;display: block;-webkit-box-align: inherit;align-items: inherit;min-height: inherit}
    div[id*=bite].modal.position-top-left .modal-dialog {margin: 28px auto 28px 28px;display: block;-webkit-box-align: inherit;align-items: inherit;min-height: inherit}
    div[id*=bite].modal.position-top-right .modal-dialog {margin: 28px 28px 28px auto;display: block;-webkit-box-align: inherit;align-items: inherit;min-height: inherit}
    div[id*=bite].modal.position-center-center .modal-dialog {margin: 28px auto;display: flex;-webkit-box-align: center;align-items: center;min-height: calc(100% - (28px * 2))}
    div[id*=bite].modal.position-center-left .modal-dialog {margin: 28px auto 28px 28px;display: flex;-webkit-box-align: center;align-items: center;min-height: calc(100% - (28px * 2))}
    div[id*=bite].modal.position-center-right .modal-dialog {margin: 28px 28px 28px auto;display: flex;-webkit-box-align: center;align-items: center;min-height: calc(100% - (28px * 2))}
    div[id*=bite].modal.position-bottom-center .modal-dialog {margin: 28px auto;display: flex;-webkit-box-align: end;align-items: flex-end;min-height: calc(100% - 56px)}
    div[id*=bite].modal.position-bottom-left .modal-dialog {margin: 28px auto 28px 28px;display: flex;-webkit-box-align: end;align-items: flex-end;min-height: calc(100% - 56px)}
    div[id*=bite].modal.position-bottom-right .modal-dialog {margin: 28px 28px 28px auto;display: flex;-webkit-box-align: end;align-items: flex-end;min-height: calc(100% - 56px)}
    
    div[id*=bite] .col-lg-auto {-webkit-box-flex: 0;-ms-flex: 0 0 auto;flex: 0 0 auto;width: auto;max-width: none}
    
    div[id*=bite] .col-lg-1 {-webkit-box-flex: 0;-ms-flex: 0 0 8.333333%;flex: 0 0 8.333333%;max-width: 8.333333%}
    div[id*=bite] .col-lg-2 {-webkit-box-flex: 0;-ms-flex: 0 0 16.666667%;flex: 0 0 16.666667%;max-width: 16.666667%}
    div[id*=bite] .col-lg-3 {-webkit-box-flex: 0;-ms-flex: 0 0 25%;flex: 0 0 25%;max-width: 25%}
    div[id*=bite] .col-lg-4 {-webkit-box-flex: 0;-ms-flex: 0 0 33.333333%;flex: 0 0 33.333333%;max-width: 33.333333%}
    div[id*=bite] .col-lg-5 {-webkit-box-flex: 0;-ms-flex: 0 0 41.666667%;flex: 0 0 41.666667%;max-width: 41.666667%}
    div[id*=bite] .col-lg-6 {-webkit-box-flex: 0;-ms-flex: 0 0 50%;flex: 0 0 50%;max-width: 50%}
    div[id*=bite] .col-lg-7 {-webkit-box-flex: 0;-ms-flex: 0 0 58.333333%;flex: 0 0 58.333333%;max-width: 58.333333%}
    div[id*=bite] .col-lg-8 {-webkit-box-flex: 0;-ms-flex: 0 0 66.666667%;flex: 0 0 66.666667%;max-width: 66.666667%}
    div[id*=bite] .col-lg-9 {-webkit-box-flex: 0;-ms-flex: 0 0 75%;flex: 0 0 75%;max-width: 75%}
    div[id*=bite] .col-lg-10 {-webkit-box-flex: 0;-ms-flex: 0 0 83.333333%;flex: 0 0 83.333333%;max-width: 83.333333%}
    div[id*=bite] .col-lg-11 {-webkit-box-flex: 0;-ms-flex: 0 0 91.666667%;flex: 0 0 91.666667%;max-width: 91.666667%}
    div[id*=bite] .col-lg-12 {-webkit-box-flex: 0;-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%}
}
@media (max-width: 575px) {
    div[id*=bite] .popup-title br,
    div[id*=bite] .popup-text br {display: none}
    div[id*=bite] .form-group {margin-right: 0 !important}
    div[id*=bite] .form-check-input {margin-top: 7px}
}