﻿/**
    MIXINS

    [1]. Used to clear floating elements within a container
**/

// [1]
@mixin clearfix {
    &::after {
        clear: both;
        content: "";
        display: table;
    }
}

@function fontsize($block, $size) {
    @if not map-has-key($fontsize, $block) {
      @warn "No palette found for `#{$block}` in $styleguide map. Property omitted.";
    }

    @return map-get(map-get($fontsize, $block), $size);
}

@function palette($palette, $tone) {
    @if not map-has-key($palettes, $palette) {
      @warn "No palette found for `#{$palette}` in $palettes map. Property omitted.";
    }

    @return map-get(map-get($palettes, $palette), $tone);
}
