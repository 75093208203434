/******** SHOP *********/
body.no-content-padding:not(.with_aside).plan-page, body.no-content-padding:not(.with_aside).builders-page {
	// #plan, #shop {
	// 	padding-top: 150px !important;
	// }
	// .overlay-menu-toggle {
	// 	color: $brown !important;
	// }
	div.social-icons {
		display: none !important;
	    // a {
	    // 	color: $brown;
	    // }
	}
}

/******** PLAN *********/
.plan-page, .builders-page {
	&.header-overlay .overlay-menu-toggle {
		left: inherit;
		right: 30px;
		color: $brown !important;
		display: none !important;
	}
	#Top_bar.plans-menu {
		top: 15px;
		.container {
			width: 100%;
			max-width: 1600px;
		}
		.menu>li>a {
			color: black;
		}
		#new-menu>ul>li>a, .action_button {
			font-size: 18px;
			font-family: $hfont;
			span {
				border: none !important;
			}
		}
		.menu_wrapper {
			float: right !important;
		}
	}
}
#plan {
	font-size: 1.3em;
	background: $gray !important;
	background-color: $gray !important;
	a.plan_cta {
		background: $orange !important;
		border-color: $orange !important;
		color: white !important;
	}
	.strike {
		text-decoration: line-through;
	}
	#Intro {
		padding-top: 90px;
		background-color: white;
		.container {
			width: 100%;
			max-width: 1600px;
			.title-container {
				width: 33.33333333333333% !important;
    			float: left !important;
    			padding-left: 8px !important;
			    padding-right: 8px !important;
			    min-height: 1px !important;
			    position: relative !important;
			    &.column {
			    	margin: 0 !important;
			    }
			    .title-box {
			    	width: 100% !important; 
			    	height: 600px; 
			    	background-color: #f2f2f2; 
			    }
			    .title-holder {
					background-color: #f2f2f2;
					max-width: 100% !important;
				    max-height: 100% !important;
				    padding: 40px !important;
				    text-align: left;
				    box-sizing: border-box;
				    div.profile {
				    	margin-bottom: 30px;
				    	img {
							background: #D8D8D8 !important;
						    border-radius: 50% !important;
						    border: 2px solid #ffffff !important;
						    overflow: hidden !important;
						    width: 70px;
						    height: 70px;
						    display: inline-block;
						}
						.architect-info {
							display: inline-block;
							height: 70px;
							vertical-align: top;
							margin-left: 20px;
						}
						.architect-name {
							color: black;
							margin-bottom: 0;
							margin-top: 16px;
							line-height: 19px;
						}
						.architect-description {
							font-size: 14px;
						}
				    }
				    h1 {
						font-size: 45px;
						line-height: 50px;
						font-family: $pfont;
						font-weight: 500;
						letter-spacing: 2px;
					}
					div.reviews {
						margin-bottom: 50px;
						i {
							color: $orange;
							font-size: 15px;
							margin: 0 -5px;
						}
						span {
							color: black;
							margin-left: 5px;
							font-size: 14px;
						}
					}
					.icons {
						margin-top: 40px;
						.column {
							text-align: center;
							color: black;
							i {
								font-size: 60px;
							}
							p {
								margin-top: 20px;
							}
						}
					}
					div.categories {
						span, a {
							color: black;
							text-transform: uppercase;
							font-family: $hfont;
							letter-spacing: 2px;
							font-size: 12px;
						}
					}
				}
			}
			.slider-container {
				width: 63% !important;
    			float: left !important;
    			padding-left: 8px !important;
			    padding-right: 8px !important;
			    min-height: 1px !important;
			    position: relative !important;
			    &.column {
			    	margin: 0 !important;
			    }
			    .plans-slider-container {
					position: relative;
					.plans-slider-image {
						width: 100%; 
						height: 600px; 
						background-size: cover; 
						background-position: center center; 
						background-repeat: no-repeat;
					}
					.plans-slider-arrows {
						position: absolute;
					    display: flex;
					    justify-content: space-between;
					    width: 100%;
					    top: 50%;
					    transform: translateY(-50%);
					    transition: 0.3s all ease-out;
					    margin: 0;
					    padding: 0;
					    list-style-type: none; 
					    li {
					    	display: inline-block;
						    width: 60px;
						    height: 60px;
						    background-color: rgba(0, 0, 0, 0.8);
						    color: #ffffff;
						    line-height: 60px;
						    font-size: 30px;
						    text-align: center;
						    cursor: pointer;
						    margin: 0;
						    padding: 0;
						    list-style-type: none;
					    }
					}
				}
				.plans-slider-navigation {
					background: white !important;
					background-color: white !important;
					padding: 10px 5px 0;
					height: 100%;
					// span.nav-arrow {
					//     position: absolute;
					//     left: 50%;
					//     font-size: 30px;
					//     transform: translateX(-50%) rotate(90deg);
					//     &.icon-left-open {
					//     	top: 10px;
					//     }
					//     &.icon-right-open {
					//     	bottom: 10px;
					//     }
					// }
					.plans-slider-item {
						margin: 0 6px 0 5px;
						min-height: 100px;
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center;
					}
				}
				.slider-left-column {
					width: 100%;
					margin: 0
				}
				.slider-right-column {
					width: 100%;
					margin: 0;
					display: none !important;
				}
				div.slider-cta {
					position: absolute;
					right: 32px;
					a.neutral_theme {
						margin: 0 !important;
						background-color: #f2f2f2 !important;
						color: #484848 !important;
						z-index: 10;
						letter-spacing: 2px;
						font-size: 14px;
						.button_label {
							padding: 6px 20px;
						}
					}
					&.details-cta {
						bottom: 24px;
					}
					&.love-cta {
						top: 24px;
						a.neutral_theme {
							font-size: 20px;
							.button_label {
								padding: 6px 6px 6px 8px;
							}
							&:hover {
								.button_label i.moon-heart_empty {
									&:before {
										content: "\e905";
									}
								}
							}
						}
					}
				}
			}
		}
	}
	#Content {
		background: white;
		.section_wrapper, .container {
			width: 960px;
			p, label, div {
				color: black;
			}
		}
		#sidebar.plan-options {
			margin-left: 2%;
		}
		#Description:focus {
			outline: none;
		}
		#plan-options {
			background: #f2f2f2 !important;
			background-color: #f2f2f2 !important;
			padding: 20px 20px;
			border-radius: 4px;
			position: relative;
			margin-bottom: 20px;
			label {
				margin-top: 20px;
				span {
					font-size: 0.7em; 
					position: absolute;
					right: 20px;
					a {
						color: $brown;
					}
				}
			}
			.selectem_label .pull-right {
				right: 30px;
			}
			.price-box {
				margin-top: 20px;
				text-align: center;
				p {
					text-align: left;
					margin-bottom: 5px;
					height: 20px;
					span {
						position: absolute;
						right: 20px;
						font-weight: 700;
					}
					&.question {
						font-size: 0.7em;
						margin-bottom: 20px;
						span {
							color: $brown;
						}
					}
				}
				span.phone {
					display: block;
					font-size: 0.8em;
					text-align: center;
				}
			}
			.selectem {
				position: relative;
			}
			.selectem.is-active .selectem_dropdown {
				width: 100%;
			}
			// .add_to_cart {
			// 	position: unset;
			// 	span {
			// 		position: unset;
			// 	}
			// }
			.pull-right {
				font-weight: 700;
				position: absolute;
				right: 20px;
			}
			.selectem-search-hide {
				display: none !important;
			}
			ul.selectem-items {
				box-shadow: 0 0 10px rgba(75,75,75,0.5);
    			border: 1px solid #bbb;
    			position: absolute;
    			border-radius: 4px;
			    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
			    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
			    background-clip: padding-box;
			    z-index: 1000;
				li {
					padding: 10px;
					border: 1px solid $gray;
					cursor: pointer;
					background-color: white !important;
					&.not-available {
						background-color: #f2f2f2 !important;
						cursor: not-allowed;
					}
					span.recommanded {
						display: block;
						font-weight: 700;
						font-size: 0.8em;
						margin: 7px 0 -4px;
					}
					p.description {
						font-size: 0.7em;
						opacity: 0.7;
						margin: 7px 0 10px;
					}
				}
			}
			.instant-download {
				margin-top: 5px;
			    background: $orange;
			    color: white;
			    text-transform: uppercase;
			    font-size: .6em;
			    line-height: 1.2;
			    font-weight: 400;
			    position: relative;
			    text-align: center;
			    padding: 4px 0 2px;
			    &:before {
			    	content: '';
				    display: inline-block;
				    position: absolute;
				    left: 0;
				    top: 0;
				    width: 0;
				    height: 0;
				    border-top: 9px solid transparent;
				    border-bottom: 9px solid transparent;
				    border-left: 9px solid #f2f2f2;
			    }
			    &:after {
			    	content: '';
				    display: inline-block;
				    position: absolute;
				    right: 0;
				    top: 0;
				    width: 0;
				    height: 0;
				    border-top: 9px solid transparent;
				    border-bottom: 9px solid transparent;
				    border-right: 9px solid #f2f2f2;
			    }
			}
		}
		#cost-to-build, #customize-plan {
			background: #f2f2f2 !important;
			background-color: #f2f2f2 !important;
			padding: 20px 20px;
			border-radius: 4px;
			position: relative;
			text-align: center;
			p {
				text-align: left;
				font-size: 0.9em;
			}
			a.cost_to_build {
				padding: 0;
				// &.customize {
				// 	background: $brown !important;
				// 	background-color: $brown !important;
				// }
			}
		}
		#plan-description {
			margin-left: 0;
			h3 {
				line-height: 1;
			    padding-bottom: 7px;
			    border-bottom: 1px solid #cccccc;
			    text-transform: uppercase;
			    font-size: 26px;
			    margin-bottom: 30px;
			}
			h4 {
				font-size: 22px;
				line-height: 1;
			}
			.desc-row {
				&.icons {
					.column {
						text-align: center;
						i {
							font-size: 60px;
						}
						p {
							margin-top: 20px;
						}
					}
				}
				&.specs {
					.one {
						border-bottom: 1px solid #cccccc;
						margin-bottom: 30px;
					}
					.one-third {
						margin-bottom: 30px;
						span {
							display: block;
							font-weight: 700;
						}
					}
				}
				&.included {
					a {
						color: $brown;
						text-decoration: underline;
					}
					ul {
						margin-top: 20px;
					}
					h5 {
						font-size: 1em;
						font-family: $pfont;
						text-decoration: underline;
					}
				}
				&.pricing {
					.main-row {
						margin-bottom: 30px;
						.plan-set-options {
							span {
								text-decoration: underline;
							}
						}
					}
					.column {
						position: relative;
						margin: 0;
						min-height: 70px;
						border: 1px solid #cccccc;
						font-size: 14px;
						p {
							position: absolute;
							top: 50%;
							transform: translate(0, -50%);
						}
						&.options {
							width: 25%;
							text-align: right;
							p {
								right: 10px;
								font-size: 1.2em;
							}
							&.first {
								background: rgb(242, 249, 241);
							}
						}
						&.price {
							width: 15%;
							background: #e6e7e8;
							p {
								left: 50%;
								transform: translate(-50%, -50%);
								font-size: 1.2em;
							}
							&.first {
								background: rgb(209, 234, 205);
							}
						}
						&.explanation {
							width: 50%;
							p {
								width: 90%;
								left: 10px
							}
							&.first {
								background: rgb(242, 249, 241);
							}
						}
					}
				}
			}
			#plan-options {
				display: none !important
			}
			#cost-to-build, #customize-plan {
				display: none !important;
			}
		}
		#plan-support {
			padding: 60px 0;
			p {
				margin-bottom: 30px;
			}
		}
	}
	#whats-included {
		h1 {
			padding-bottom: 9px;
    		margin: 40px 0 20px;
    		border-bottom: 1px solid #ebebeb;
    		font-size: 36px;
		}
		h2 {
			font-size: 30px;
			line-height: 34px;
			margin-bottom: 10px;
		}
		h3 {
			font-size: 26px;
			line-height: 30px;
			margin-top: 20px;
		}
		h4 {
			font-size: 22px;
			line-height: 26px;
		}
		span.notice {
			font-size: 16px;
			font-style: italic;
		}
		div.sample-plan-content {
			position: relative;
			.description {
				// position: absolute;
				// top: 50%;
				// left: 50%;
				// transform: translate(-50%,-50%);
				width: 100%;
				padding-top: 25px;
			}
		}
		ul {
			color: black;
			font-size: inherit;
			line-height: inherit;
		}
	}
}
#give-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9915;
	background: $gray;
	display: none;
	font-size: 18px;
	a.close-overlay {
		position: absolute;
	    left: 30px;
	    top: 30px;
	    font-size: 30px;
	    z-index: 9916;
	    i {
	    	color: $brown;
	    }
	}
	a.give_us_add {
		background: $brown !important;
	    border-color: $brown !important;
	    color: $gray !important;
	    margin-top: 30px;
	    &:hover {
	    	background: $brown !important;
	    	border-color: $brown !important;
	    	color: $gray !important;
	    }
	}
	#give-what-you-want {
		position: absolute;
		width: 700px;
		left: 50%;
		margin-left: -350px;
		top: 50%;
		margin-top: -150px;
		text-align: center;
		color: $brown;
		h4 {
			color: $brown;
		}
		.donation-sentence {
			font-size: 1.2em;
			.donation {
				font-weight: 700;
			}
		}
		.emoji {
			max-height: 2em;
			transform: scale(0.9);
			transition: transform 0.2s ease-out;
		}
		.emoji-slider-question {
			.emoji {
				max-height: 1.4em;
			}
		}
		@media screen and (max-width: 950px) {
		  	.emoji {
		    	transform: scale(0.7); 
		    } 
		}
		.emoji-slider {
		  	height: 5px;
		  	margin-top: 100px;
		  	background: $gray;
			.ui-slider-handle {
				top: -6px;
				height: 16px;
				width: 16px;
				transform: rotateZ(45deg); 
			}
			.ui-slider-pip {
				top: -50px;
				margin-left: -1.2em; 
			}
			.ui-slider-handle {
				&.ui-state-default {
					background-color: $brown;
					background: $brown;
				}
				&.ui-state-active {
					background-color: $brown;
					background: $brown;
				}
			}
			.ui-slider-pip-selected .emoji {
				transform: scale(1.3) translateY(-5px); 
			}

			.ui-slider-line {
				// width: 2px;
		  //       height: 10px;
		  //       margin-left: -1px; 
		  //       background: $brown;
    // 			transition: all 0.4s ease;
    			display: none;
			}

			@media screen and (max-width: 950px) {
		  		.ui-slider-pip-selected .emoji {
		    		transform: scale(1.1) translateY(-5px); 
		    	} 
		    }
		}
	}
}
#plan-list {
	margin-top: 90px;
	#plan-intro {
		padding-top: 50px;
		padding-bottom: 0px; 
		background-color:white;
		h1 {
			color: $brown;
			text-transform: uppercase;
			text-align: center;
			font-size: 40px; 
			line-height: 45px;
			margin-bottom: 40px;
		}
		p, span {
			color: $brown;
			font-size: 1.4em;
			font-family: $hfont;
			span {
				font-size: 1em;
			}
		}
		.nb {
			float: left;
		}
		.filters {
			float: right;
			a {
				background: $gray;
    			border-color: $gray;
    			color: $brown;
    			display: inline-block;
    			font-weight: 500;
    			padding: 0 20px;
    			font-family: $hfont;
    			font-size: 1.2em;
    			-webkit-transition: 0.3s background-color;
    			-moz-transition: 0.3s background-color;
    			-ms-transition: 0.3s background-color;
    			-o-transition: 0.3s background-color;
    			transition: 0.3s background-color;
    			text-decoration: none !important;
    			-webkit-border-radius: 2px;
				-moz-border-radius: 2px;
				border-radius: 2px;
    			&:hover, &.active {
    				background: $brown;
    				border-color: $brown;
    				color: $gray;
    			}
    			&:first-of-type {
    				margin-left: 20px; 
    			}
			}
		}
	}
	#plan-masonry {
		padding-top:0px; 
		padding-bottom:0px; 
		background-color:#fff;
		.plan-item {
			width: 49.8%;
			cursor: pointer;
			position: relative;
			&:nth-of-type(2n+1) {
				padding-right: 0.2%;
			}
			&:nth-of-type(2n) {
				padding-left: 0.2%;
			}
			.plan-photo-wrapper {
				position: relative;
				.plans-list-images {
				    background: url(/img/loader.gif);
				    background-position: center 140px;
				    background-repeat: no-repeat;
				    -webkit-transition: 0.5s;
				    -moz-transition: 0.5s;
				    -ms-transition: 0.5s;
				    -o-transition: 0.5s;
				    transition: 0.5s;
				}
				.plans-list-images .plan-photo:not(:first-child) { 
				    opacity: 0;
				    transition: 1s opacity; 
				}
				.plans-list-images.slick-initialized {
				    background: none;
				}
				.plans-list-images.slick-initialized .plan-photo:not(:first-child) { 
				    opacity: 1;
				}
				.plan-photo {
					background-size: cover; 
					background-repeat: no-repeat; 
					background-position: center center; 
					height: 300px; 
					width: 100%;
				}
				.plans-slider-arrows {
					position: absolute;
				    display: flex;
				    justify-content: space-between;
				    width: 100%;
				    top: 50%;
				    transform: translateY(-50%);
				    transition: 0.3s all ease-out;
				    margin: 0;
				    padding: 0;
				    list-style-type: none;
				    opacity: 0.25; 
				    li {
				    	display: inline-block;
					    width: 60px;
					    height: 60px;
					    background-color: rgba(0, 0, 0, 0.8);
					    color: #ffffff;
					    line-height: 60px;
					    font-size: 30px;
					    text-align: center;
					    cursor: pointer;
					    margin: 0;
					    padding: 0;
					    list-style-type: none;
				    }
				}
				.plan-architect {
					position: absolute;
					bottom: -30px;
					right: 30px;
					span {
						font-family: $hfont;
					}
					img {
						background: #D8D8D8 !important;
					    border-radius: 50% !important;
					    border: 3px solid #ffffff !important;
					    overflow: hidden !important;
					    width: 60px;
					    height: 60px;
					    display: inline-block;
					    margin-bottom: -3px;
					}
				}
			}
			.plan-title-wrapper {
				padding: 10px 230px 10px 30px;
				h3 {
					font-size: 24px;
					line-height: 30px;
					margin-bottom: 5px;
				}
			}
			.plan-desc-wrapper {
				padding: 0px 30px 20px;
				float: left;
				width: 100%;
				color: black;
				.plan-desc {
					width: 33%;
					margin: 0;
					float: left;
					p {
						display: inline;
						font-family: $hfont;
					}
					i {
						font-size: 20px;
						margin-right: 10px;
						display: inline
					}
				}
			}
			.on-sale {
				display: none !important;
			}
			&.featured .on-sale {
				display: block !important;
				position: absolute;
				top: 20px;
				left: 20px;
				height: 30px;
				background-color: $brown;
				padding: 3px 15px 5px;
				-webkit-border-radius: 2px;
				-moz-border-radius: 2px;
				border-radius: 2px;
				p {
					color: $gray;
					line-height: 30px;
					font-size: 20px;
					font-family: $hfont;
				}
			}
		}
	}
	.why-our-plans-description {
		margin: 0px auto 50px; 
		color: black; 
		max-width: 800px; 
		text-align: center;
	}
	.why-us {
		text-align: center;
		span {
			font-size: 40px;
			color: $brown;
		}
		h5 {
			font-size: 26px;
			line-height: 30px;
			margin-top: 20px;
		}
	}
	.plan-list-support {
		color: black;
		h5 {
			font-size: 26px;
			line-height: 30px;
		}
		.middle-text {
			margin-bottom: 30px;
			p {
				margin-bottom: 0;
			}
		}
		span {
			font-size: 1.3em;
		}
	}
}

ul#accordion {
    list-style: none;
    padding: 0;
    max-width: 800px;
    margin: auto;
    .inner {
        padding: 15px 1em;
        overflow: hidden;
        display: none;
        color: black;
        text-align: left !important;
      
        &.show {
          display: block;
        }
        ul {
        	margin-bottom: 15px;
        	margin-left: 20px;
        	li {
        		text-align: left;
        		list-style-type: disc;
        	}
        }
    }
  
    li {
        margin: .5em 0;
      	text-align: center;
        a.toggle {
            max-width: 100%;
            display: block;
            background: white;
            color: black;
            padding: .75em;
            border-radius: 0.15em;
            transition: background .3s ease;
            border: 1px solid #EBEBEB;
            font-family: $hfont;
            font-size: 16px;

            // &:hover {
            //     background: rgba(0, 0, 0, 0.9);
            // }
        }
    }
}

/******** RESPONSIVE ********/

@media only screen and (max-width: 959px) {
	#plan {
		#whats-included {
			div.sample-plan-content {
				.description {
					padding-top: 10px;
					p {
						font-size: 16px;
					}
				}
			}
		}
	}
	#plan-list {
		#plan-intro {
			padding-bottom: 20px;
			h1 {
				font-size: 35px;
				line-height: 40px;
			}
			.nb, .filters {
				float: none;
				display: block;
				text-align: center;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	body.no-content-padding:not(.with_aside).plan-page, body.no-content-padding:not(.with_aside).builders-page {
		// #plan, #shop {
		// 	padding-top: 100px !important;
		// }
		.overlay-menu-toggle {
			color: $brown !important;
		}
	}
	.plan-page, .builders-page {
		&.header-overlay .overlay-menu-toggle {
			display: block !important;
		}
		#Top_bar.plans-menu {
			background-color: white !important;
			background: white !important;
			top: 0;
			.logo {
				text-align: left;
				width: initial;
				#logo {
					margin-left: 10px;
				}
			}
			.menu_wrapper {
				display: none !important;
			}
		}
	}
	#plan {
		#Intro {
			.container {
				.title-container {
					display: none !important;
				}
				.slider-container {
					width: 100% !important;
					padding-left: 0 !important;
					padding-right: 0 !important;
					.plans-slider-container {
						.plans-slider-image {
							height: 400px !important;
						}
						.plans-slider-arrows {
							display: none !important;
						}
					}
					.slider-right-column {
						display: block !important;
					}
					div.slider-cta {
						display: none !important;
					}
				}
			}
		}
		#Content {
			#sidebar-plan {
				display: none !important;
			}
			#plan-description {
				.desc-row {
					overflow: auto;
					h3 {
						margin-bottom: 20px;
					}
					&.icons {
						.column {
							width: 48% !important;
							clear: none !important;
						}
					}
					&.specs {
						margin-bottom: 40px;
						.one {
							border-bottom: none !important;
							margin-bottom: 15px;
							
						}
						.one-third {
							margin-bottom: 0px;
							p {
								font-weight: 700;
							}
							span {
								display: block;
								font-weight: 400;
							}
						}
					}
					&.pricing {
						display: none !important;
					}
				}
				#plan-options {
					display: block !important;
					ul {
						list-style: none;
						list-style-type: none;
						margin: 0 !important;
						li {
							list-style-type: none;
							list-style: none;
						}
					}
				}
				#cost-to-build, #customize-plan {
					display: block !important;
					margin-bottom: 30px;
				}
			}
		}
	}
	#give-overlay {
		#give-what-you-want {
			width: 90%;
			left: 5%;
			margin-left: 0;
			h4 {
				font-size: 24px;
			}
			.emoji-slider {
				.ui-slider-pip-label {
					&:nth-child(2n+1) {
						display: none;
					}
				}
			}
		}
	}
	#plan-list {
		#plan-intro {
			h1 {
				font-size: 30px;
				line-height: 35px;
				margin-bottom: 30px;
			}
			.filters a {
				font-size: 1.6em;
				padding-top: 5px;
				padding-bottom: 5px;
				&:first-of-type {
					margin-left: 0px;
				}
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	#plan-list {
		#plan-masonry {
			.plan-item {
				.plan-title-wrapper {
					padding: 40px 20px 10px 20px;
				}
				.plan-desc-wrapper {
					padding-right: 20px;
					padding-left: 20px;
				}
			}
		}
	}
}
@media only screen and (max-width: 479px) {
	#plan {
		#Intro {
			.container {
				.slider-container {
					.plans-slider-container {
						.plans-slider-image {
							height: 300px !important;
						}
					}
					.plans-slider-navigation {
						.plans-slider-item {
							min-height: 80px;
						}
					}
				}
			}
		}
		#whats-included {
			h4 {
				font-size: 22px;
				line-height: 24px;
			}
		}
	}
	#plan-list {
		#plan-intro {
		}
		#plan-masonry {
			.plan-item {
				.plan-desc-wrapper {
					.plan-desc {
						width: 50%;
					}
				}
			}
		}
	}	
}
@media only screen and (max-width: 400px) {
	#plan {
		#Intro {
			.container {
				.slider-container {
					.plans-slider-container {
						.plans-slider-image {
							height: 250px !important;
						}
					}
					.plans-slider-navigation {
						.plans-slider-item {
							min-height: 70px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1921px) {

}

@media only screen and (min-width: 960px) {

}
@media only screen and (min-width: 768px) {

}	
@media only screen and (min-width: 480px) and (max-width: 768px) {
	
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
	#plan {
		#Intro {
			.container {
				.title-container {
					width: 45% !important;
					.title-box {
						height: 375px !important;
						.title-holder {
							h1 {
								font-size: 30px;
								line-height: 38px;
							}
							div.profile {
								margin-bottom: 20px;
								img {
									width: 50px;
									height: 50px;
								}
								.architect-info {
									height: 50px;
									max-width: 170px;
								}
								.architect-name {
									margin-top: 10px;
									line-height: 15px;
								}
							}
							div.reviews {
								margin-bottom: 30px;
							}
						}
					}
				}
				.slider-container {
					width: 50% !important;
					.plans-slider-container {
						.plans-slider-image {
							height: 375px !important;
						}
					}
				}
			}
		}
	}
	#plan-list {
		#plan-masonry {
			.plan-item {
				.plan-title-wrapper {
					padding: 40px 20px 10px 20px;
				}
				.plan-desc-wrapper {
					padding-right: 20px;
					padding-left: 20px;
				}
			}
		}
	}
}
@media only screen and (min-width: 960px) and (max-width: 1239px) {
	#plan {
		#Intro {
			.container {
				.title-container {
					width: 40% !important;
					.title-box {
						height: 450px !important;
						.title-holder {
							h1 {
								font-size: 40px;
								line-height: 45px;
								letter-spacing: 1px;
							}
							div.profile {
								margin-bottom: 20px;
								img {
									width: 60px;
									height: 60px;
								}
								.architect-info {
									height: 60px;
								}
								.architect-name {
									margin-top: 15px;
									line-height: 15px;
								}
							}
							div.reviews {
								margin-bottom: 35px;
							}
						}
					}
				}
				.slider-container {
					width: 55% !important;
					.plans-slider-container {
						.plans-slider-image {
							height: 450px !important;
						}
					}
				}
			}
		}
	}
}

