/* Top Bar --------------------------------------------------------------------------- */

#Top_bar {
	position: absolute;
	left: 0;
	top: 61px;
	width: 100%;
	z-index: 30;
}
#Top_bar .column {
	margin-bottom: 0;
}
.layout-full-width.header-fw #Action_bar .container, .layout-full-width.header-fw #Top_bar .container {
	max-width: 100%;
}
#Top_bar .top_bar_left {
	position: relative;
	float: left;
}
#Top_bar .top_bar_right {
	float: right;
	height: 90px;
	position: relative;
	top: -4px;
	padding: 0 10px 0 20px;
}
#Top_bar .top_bar_right:before {
	content: "";
	display: block;
	height: 90px;
	width: 10px;
	position: absolute;
	left: -10px;
	top: 2px;
	background-image: url(../images/top_bar_right_shadow.png);
	background-repeat: repeat-y;
	-moz-transform: skewX(0deg) skewY(-25deg);
	-webkit-transform: skewX(0deg) skewY(-25deg);
	-o-transform: skewX(0deg) skewY(-25deg);
	-ms-transform: skewX(0deg) skewY(-25deg);
	transform: skewX(0deg) skewY(-25deg);
}
.ie #Top_bar .top_bar_right:before {
	width: 11px;
}

/* Top bar width */

#Top_bar .top_bar_left {
	width: 990px;
}

/* Logo */

#Top_bar .logo {
	float: left;
	margin: 0 30px 0 20px;
}
#Top_bar .logo h1 {
	margin: 0;
}
#Top_bar .logo:not(.text-logo) h1 {
	line-height: 0;
	font-size: 0;
	margin: 0;
}
#Top_bar #logo {
	display: block;
	height: 60px;
	line-height: 60px;
	padding: 15px 0px;
}

/* overflow: hidden; */

#Top_bar #logo:hover {
	text-decoration: none;
}
#Top_bar #logo img {
	vertical-align: middle;
	max-height: 100%;
}
#Top_bar #logo img.logo-sticky, #Top_bar #logo img.logo-mobile {
	display: none;
}
@media only screen and (min-width: 1240px) {
	.menuo-right #Top_bar .menu_wrapper {
		float: right;
	}
}
.header-transparent.ab-hide #Top_bar {
	top: 30px;
}
@media only screen and (max-width: 767px) {
	.header-transparent.ab-hide #Top_bar {
		top: 0px;
	}
}

/* Masonry tiles */

.masonry.tiles {
	position: relative;
}
.masonry.tiles .post-item {
	margin: 0 !important;
	overflow: hidden;
	background-color: transparent;
}
.masonry.tiles .post-item:not(.no-img) .post-desc-wrapper {
	position: absolute;
	z-index: 4;
	left: 0;
	bottom: -20px;
}
.masonry.tiles .post-item:not(.no-img) .post-desc-wrapper .post-desc {
	background: none;
	padding: 70px 30px 30px;
}
.masonry.tiles .post-item .post-desc-wrapper .post-desc {
	padding: 50% 30px 10px;
}
.masonry.tiles .post-item .post-desc-wrapper .post-desc .post-meta .author-date .post-links {
	display: inline-block;
	margin-left: 10px;
}
.masonry.tiles .post-item .post-desc-wrapper .post-desc .post-excerpt {
	display: none;
}

/* Photo wrapper */

.masonry.tiles .post-item:not(.no-img) .post-photo-wrapper {
	line-height: 0;
	position: relative;
}
.masonry.tiles .post-item:not(.no-img) .post-photo-wrapper:after {
	content: "";
	position: absolute;
	z-index: 2;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .2);
	opacity: 0;
	transition: all 0.6s ease-out;
}
.masonry.tiles .post-item:not(.no-img):hover .post-photo-wrapper:after {
	opacity: 1;
}

/* Posts */

.masonry.tiles .format-link .post-title .icon-link {
	display: none;
}
.masonry.tiles .format-link .post-title .link-wrapper {
	margin-left: 0;
	padding-top: 5px;
}
.masonry.tiles .format-quote blockquote {
	margin-left: 0;
	top: 0;
	margin-bottom: 25px;
}
.masonry.tiles .format-quote blockquote:after {
	display: none;
}
.masonry.tiles .format-quote blockquote a {
	text-decoration: none;
}

/* Post icon */

.masonry.tiles .post-item .post-format-icon {
	position: absolute;
	z-index: 3;
	left: 25px;
	top: 25px;
	font-size: 35px;
	line-height: 35px;
	color: #fff;
}

/* Line */

.masonry.tiles .post-item .post-desc-wrapper .post-desc .post-title:after {
	content: "";
	display: block;
	height: 3px;
	margin-top: 20px;
	width: 0;
	transition: all 0.4s ease-out;
}
.masonry.tiles .post-item:hover .post-desc-wrapper .post-desc .post-title:after {
	width: 40%;
}

/* Desc wrapper animation */

.masonry.tiles .post-item .post-desc-wrapper {
	transition: all 0.4s ease-out;
}
.masonry.tiles .post-item:hover .post-desc-wrapper {
	transform: translateY(-20px);
}

/* Columns 2-6 */

.posts_group.masonry.tiles.col-2 .post-item {
	width: 49.99%;
}
.posts_group.masonry.tiles.col-3 .post-item {
	width: 33.33%;
}
.posts_group.masonry.tiles.col-4 .post-item {
	width: 24.99%;
}
.posts_group.masonry.tiles.col-5 .post-item {
	width: 19.99%;
}
.posts_group.masonry.tiles.col-6 .post-item {
	width: 16.66%;
}

/* Colors */

.masonry.tiles .post-item.format-quote blockquote, .masonry.tiles .post-item.format-quote blockquote a, .masonry.tiles .post-item.format-link .post-title .icon-link, .masonry.tiles .post-item.format-link .post-title .link-wrapper h4, .masonry.tiles .post-item.format-link .post-title .link-wrapper a, .masonry.tiles .post-item .post-desc-wrapper .post-desc .post-title .entry-title a {
	color: #fff;
}
.masonry.tiles .post-item.no-img .post-desc-wrapper .post-desc .post-title:after, .masonry.tiles .post-item.format-quote .post-desc-wrapper .post-desc .post-title:after, .masonry.tiles .post-item.format-link .post-desc-wrapper .post-desc .post-title:after {
	background-color: #fff;
}
.masonry.tiles .post-item .post-desc-wrapper .post-desc .post-head .post-meta, .masonry.tiles .post-item .post-desc-wrapper .post-desc .post-head .post-meta a, .masonry.tiles .post-item .post-desc-wrapper .post-desc .post-excerpt {
	color: rgba(255, 255, 255, .7);
}
.minimalist-header-no #Header {
	min-height: 0!important;
}

/* Sizes ----------------------------------------------------------------------------- */

.with_aside .four.columns {
	float: right;
	margin: 0;
}
.section_wrapper:after, .container:after {
	clear: both;
	content: " ";
	display: block;
	height: 0;
	visibility: hidden;
}
.mcb-wrap {
	float: left;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.mcb-wrap.divider {
	width: 100%;
}
.column, .columns {
	float: left;
	margin: 0 1% 40px;
}
.the_content_wrapper {
	margin: 0 1%;
}
.column_content .the_content_wrapper {
	margin: 0;
}
.sidebar, .widget-area {
	box-sizing: border-box;
}

/* .mcb-wrap */

.one-sixth.mcb-wrap {
	width: 16.666%;
}

/* 1/6 */

.one-fifth.mcb-wrap {
	width: 20%;
}

/* 1/5 */

.one-fourth.mcb-wrap {
	width: 25%;
}

/* 1/4 */

.one-third.mcb-wrap {
	width: 33.333%;
}

/* 1/3 */

.two-fifth.mcb-wrap {
	width: 40%;
}

/* 2/5 */

.one-second.mcb-wrap {
	width: 50%;
}

/* 1/2 */

.three-fifth.mcb-wrap {
	width: 60%;
}

/* 3/5 */

.two-third.mcb-wrap {
	width: 66.666%;
}

/* 2/3 */

.three-fourth.mcb-wrap {
	width: 75%;
}

/* 3/4 */

.four-fifth.mcb-wrap {
	width: 80%;
}

/* 4/5 */

.five-sixth.mcb-wrap {
	width: 83.333%;
}

/* 5/6 */

.one.mcb-wrap {
	width: 100%;
}

/* 1/1 */


/* .column */

.one-sixth.column {
	width: 14.666%;
}

/* 1/6 */

.one-fifth.column {
	width: 18%;
}

/* 1/5 */

.one-fourth.column, .four.columns {
	width: 23%;
}

/* 1/4 */

.one-third.column {
	width: 31.333%;
}

/* 1/3 */

.two-fifth.column {
	width: 38%;
}

/* 2/5 */

.one-second.column {
	width: 48%;
}

/* 1/2 */

.three-fifth.column {
	width: 58%;
}

/* 3/5 */

.two-third.column {
	width: 64.666%;
}

/* 2/3 */

.three-fourth.column {
	width: 73%;
}

/* 3/4 */

.four-fifth.column {
	width: 78%;
}

/* 4/5 */

.five-sixth.column {
	width: 81.333%;
}

/* 5/6 */

.one.column {
	width: 98%;
}

/* 1/1 */

.section.full-width>.one.column, .section.full-width>.section_wrapper>.one.column, .section.full-width .one.wrap .one.column {
	width: 100%;
	margin: 0;
}
@media only screen and (min-width: 1240px) {
	body:not(.header-simple) #Top_bar #menu {
		display: block !important;
	}
	.tr-menu #Top_bar #menu {
		background: none !important;
	}
	#Top_bar .menu>li>ul.mfn-megamenu {
		width: 984px;
	}
	#Top_bar .menu>li>ul.mfn-megamenu>li {
		float: left;
	}
	#Top_bar .menu>li>ul.mfn-megamenu>li.mfn-megamenu-cols-1 {
		width: 100%;
	}
	#Top_bar .menu>li>ul.mfn-megamenu>li.mfn-megamenu-cols-2 {
		width: 50%;
	}
	#Top_bar .menu>li>ul.mfn-megamenu>li.mfn-megamenu-cols-3 {
		width: 33.33%;
	}
	#Top_bar .menu>li>ul.mfn-megamenu>li.mfn-megamenu-cols-4 {
		width: 25%;
	}
	#Top_bar .menu>li>ul.mfn-megamenu>li.mfn-megamenu-cols-5 {
		width: 20%;
	}
	#Top_bar .menu>li>ul.mfn-megamenu>li.mfn-megamenu-cols-6 {
		width: 16.66%;
	}
	#Top_bar .menu>li>ul.mfn-megamenu>li>ul {
		display: block !important;
		position: inherit;
		left: auto;
		top: auto;
		border-width: 0 1px 0 0;
	}
	#Top_bar .menu>li>ul.mfn-megamenu>li:last-child>ul {
		border: 0;
	}
	#Top_bar .menu>li>ul.mfn-megamenu>li>ul li {
		width: auto;
	}
	#Top_bar .menu>li>ul.mfn-megamenu a.mfn-megamenu-title {
		text-transform: uppercase;
		font-weight: 400;
	}
	#Top_bar .menu>li>ul.mfn-megamenu a.mfn-megamenu-title:hover {
		background: none;
	}
	#Top_bar .menu>li>ul.mfn-megamenu a .menu-arrow {
		display: none;
	}
	.menuo-right #Top_bar .menu>li>ul.mfn-megamenu {
		left: auto;
		right: 0;
	}
	.menuo-right #Top_bar .menu>li>ul.mfn-megamenu-bg {
		box-sizing: border-box;
	}
	#Top_bar .menu>li>ul.mfn-megamenu-bg {
		padding: 20px 166px 20px 20px;
		background-repeat: no-repeat;
		background-position: bottom right;
	}
	#Top_bar .menu>li>ul.mfn-megamenu-bg>li {
		background: none;
	}
	#Top_bar .menu>li>ul.mfn-megamenu-bg>li a {
		border: none;
	}
	#Top_bar .menu>li>ul.mfn-megamenu-bg>li>ul {
		background: none !important;
		-webkit-box-shadow: 0 0 0 0;
		-moz-box-shadow: 0 0 0 0;
		box-shadow: 0 0 0 0;
	}
	.header-plain:not(.menuo-right) #Header .top_bar_left {
		width: auto !important;
	}
	.header-simple {}
	.header-simple #Top_bar #menu {
		display: none;
		height: auto;
		width: 300px;
		bottom: auto;
		top: 100%;
		right: 1px;
		position: absolute;
		margin: 0px;
	}
	.header-simple #Header a.responsive-menu-toggle {
		display: block;
		line-height: 35px;
		font-size: 25px;
		position: absolute;
		right: 10px;
	}
	.header-simple #Header a:hover.responsive-menu-toggle {
		text-decoration: none;
	}
	.header-simple #Top_bar #menu>ul {
		width: 100%;
		float: left;
	}
	.header-simple #Top_bar #menu ul li {
		width: 100%;
		padding-bottom: 0;
		border-right: 0;
		position: relative;
	}
	.header-simple #Top_bar #menu ul li a {
		padding: 0 20px;
		margin: 0;
		display: block;
		height: auto;
		line-height: normal;
		border: none;
	}
	.header-simple #Top_bar #menu ul li a:after {
		display: none;
	}
	.header-simple #Top_bar #menu ul li a span {
		border: none;
		line-height: 48px;
		display: inline;
		padding: 0;
	}
	.header-simple #Top_bar #menu ul li.submenu .menu-toggle {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 48px;
		height: 48px;
		line-height: 48px;
		font-size: 30px;
		text-align: center;
		color: #d6d6d6;
		border-left: 1px solid #eee;
		cursor: pointer;
	}
	.header-simple #Top_bar #menu ul li.submenu .menu-toggle:after {
		content: "+"
	}
	.header-simple #Top_bar #menu ul li.hover>.menu-toggle:after {
		content: "-"
	}
	.header-simple #Top_bar #menu ul li.hover a {
		border-bottom: 0;
	}
	.header-simple #Top_bar #menu ul.mfn-megamenu li .menu-toggle {
		display: none;
	}
	.header-simple #Top_bar #menu ul li ul {
		position: relative !important;
		left: 0 !important;
		top: 0;
		padding: 0;
		margin-left: 0 !important;
		width: auto !important;
		background-image: none;
	}
	.header-simple #Top_bar #menu ul li ul li {
		width: 100% !important;
	}
	.header-simple #Top_bar #menu ul li ul li a {
		padding: 0 20px 0 30px;
	}
	.header-simple #Top_bar #menu ul li ul li a .menu-arrow {
		display: none;
	}
	.header-simple #Top_bar #menu ul li ul li a span {
		padding: 0;
	}
	.header-simple #Top_bar #menu ul li ul li a span:after {
		display: none !important;
	}
	.header-simple #Top_bar .menu>li>ul.mfn-megamenu a.mfn-megamenu-title {
		text-transform: uppercase;
		font-weight: 400;
	}
	.header-simple #Top_bar .menu>li>ul.mfn-megamenu>li>ul {
		display: block !important;
		position: inherit;
		left: auto;
		top: auto;
	}
	.header-simple #Top_bar #menu ul li ul li ul {
		border-left: 0 !important;
		padding: 0;
		top: 0;
	}
	.header-simple #Top_bar #menu ul li ul li ul li a {
		padding: 0 20px 0 40px;
	}
	.rtl.header-simple#Top_bar #menu {
		left: 1px;
		right: auto;
	}
	.rtl.header-simple #Top_bar a.responsive-menu-toggle {
		left: 10px;
		right: auto;
	}
	.rtl.header-simple #Top_bar #menu ul li.submenu .menu-toggle {
		left: 0;
		right: auto;
		border-left: none;
		border-right: 1px solid #eee;
	}
	.rtl.header-simple #Top_bar #menu ul li ul {
		left: auto !important;
		right: 0 !important;
	}
	.rtl.header-simple #Top_bar #menu ul li ul li a {
		padding: 0 30px 0 20px;
	}
	.rtl.header-simple #Top_bar #menu ul li ul li ul li a {
		padding: 0 40px 0 20px;
	}
	.menu-highlight #Top_bar .menu>li {
		margin: 0 2px;
	}
	.menu-highlight:not(.header-creative) #Top_bar .menu>li>a {
		margin: 20px 0;
		padding: 0;
		-webkit-border-radius: 5px;
		border-radius: 5px;
	}
	.menu-highlight #Top_bar .menu>li>a:after {
		display: none;
	}
	.menu-highlight #Top_bar .menu>li>a span:not(.description) {
		line-height: 50px;
	}
	.menu-highlight #Top_bar .menu>li>a span.description {
		display: none;
	}
	.menu-highlight.header-stack #Top_bar .menu>li>a {
		margin: 10px 0 !important;
	}
	.menu-highlight.header-stack #Top_bar .menu>li>a span:not(.description) {
		line-height: 40px;
	}
	.menu-highlight.header-fixed #Top_bar .menu>li>a {
		margin: 10px 0 !important;
		padding: 5px 0;
	}
	.menu-highlight.header-fixed #Top_bar .menu>li>a span {
		line-height: 30px;
	}
	.menu-highlight.header-transparent #Top_bar .menu>li>a {
		margin: 5px 0;
	}
	.menu-highlight.header-simple #Top_bar #menu ul li, .menu-highlight.header-creative #Top_bar #menu ul li {
		margin: 0;
	}
	.menu-highlight.header-simple #Top_bar #menu ul li>a, .menu-highlight.header-creative #Top_bar #menu ul li>a {
		-webkit-border-radius: 0;
		border-radius: 0;
	}
	.menu-highlight:not(.header-simple) #Top_bar.is-sticky .menu>li>a {
		margin: 10px 0 !important;
		padding: 5px 0 !important;
	}
	.menu-highlight:not(.header-simple) #Top_bar.is-sticky .menu>li>a span {
		line-height: 30px !important;
	}
	.header-modern.menu-highlight.menuo-right .menu_wrapper {
		margin-right: 20px;
	}
	.menu-line-below #Top_bar .menu>li>a:after {
		top: auto;
		bottom: -4px;
	}
	.menu-line-below #Top_bar.is-sticky .menu>li>a:after {
		top: auto;
		bottom: -4px;
	}
	.menu-line-below-80 #Top_bar:not(.is-sticky) .menu>li>a:after {
		height: 4px;
		left: 10%;
		top: 50%;
		margin-top: 20px;
		width: 80%;
	}
	.menu-line-below-80-1 #Top_bar:not(.is-sticky) .menu>li>a:after {
		height: 1px;
		left: 10%;
		top: 50%;
		margin-top: 20px;
		width: 80%;
	}
	.menu-arrow-top #Top_bar .menu>li>a:after {
		background: none repeat scroll 0 0 rgba(0, 0, 0, 0) !important;
		border-color: #cccccc transparent transparent transparent;
		border-style: solid;
		border-width: 7px 7px 0 7px;
		display: block;
		height: 0;
		left: 50%;
		margin-left: -7px;
		top: 0 !important;
		width: 0;
	}
	.menu-arrow-top.header-transparent #Top_bar .menu>li>a:after, .menu-arrow-top.header-plain #Top_bar .menu>li>a:after {
		display: none;
	}
	.menu-arrow-top #Top_bar.is-sticky .menu>li>a:after {
		top: 0px !important;
	}
	.menu-arrow-bottom #Top_bar .menu>li>a:after {
		background: none !important;
		border-color: transparent transparent #cccccc transparent;
		border-style: solid;
		border-width: 0 7px 7px;
		display: block;
		height: 0;
		left: 50%;
		margin-left: -7px;
		top: auto;
		bottom: 0;
		width: 0;
	}
	.menu-arrow-bottom.header-transparent #Top_bar .menu>li>a:after, .menu-arrow-bottom.header-plain #Top_bar .menu>li>a:after {
		display: none;
	}
	.menu-arrow-bottom #Top_bar.is-sticky .menu>li>a:after {
		top: auto;
		bottom: 0;
	}
	.menuo-no-borders #Top_bar .menu>li>a span:not(.description) {
		border-right-width: 0;
	}
	.menuo-no-borders #Header_creative #Top_bar .menu>li>a span {
		border-bottom-width: 0;
	}
}
@media only screen and (min-width: 1240px) {
	#Top_bar.is-sticky {
		position: fixed !important;
		width: 100%;
		left: 0;
		top: -60px;
		height: 60px;
		z-index: 701;
		background: #fff;
		opacity: .97;
		filter: alpha(opacity=97);
		-webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
	}
	.layout-boxed.header-boxed #Top_bar.is-sticky {
		max-width: 1240px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	.layout-boxed.header-boxed.nice-scroll #Top_bar.is-sticky {
		margin-left: -5px;
	}
	#Top_bar.is-sticky .top_bar_left, #Top_bar.is-sticky .top_bar_right, #Top_bar.is-sticky .top_bar_right:before {
		background: none;
	}
	#Top_bar.is-sticky .top_bar_right {
		top: -4px;
	}
	#Top_bar.is-sticky .logo {
		width: auto;
		margin: 0 30px 0 20px;
		padding: 0;
	}
	#Top_bar.is-sticky #logo {
		padding: 5px 0 !important;
		height: 50px !important;
		line-height: 50px !important;
	}
	#Top_bar.is-sticky #logo img:not(.svg) {
		max-height: 35px;
		width: auto !important;
	}
	#Top_bar.is-sticky #logo img.logo-main {
		display: none;
	}
	#Top_bar.is-sticky #logo img.logo-sticky {
		display: inline;
	}
	#Top_bar.is-sticky .menu_wrapper {
		clear: none;
	}
	#Top_bar.is-sticky .menu_wrapper .menu>li>a {
		padding: 15px 0;
	}
	#Top_bar.is-sticky .menu>li>a, #Top_bar.is-sticky .menu>li>a span {
		line-height: 30px;
	}
	#Top_bar.is-sticky .menu>li>a:after {
		top: auto;
		bottom: -4px;
	}
	#Top_bar.is-sticky .menu>li>a span.description {
		display: none;
	}
	#Top_bar.is-sticky a.responsive-menu-toggle {
		top: 14px;
	}
	#Top_bar.is-sticky .top_bar_right_wrapper {
		top: 15px;
	}
	.header-plain #Top_bar.is-sticky .top_bar_right_wrapper {
		top: 0;
	}
	#Top_bar.is-sticky .secondary_menu_wrapper, #Top_bar.is-sticky .banner_wrapper {
		display: none;
	}
	.header-simple #Top_bar.is-sticky .responsive-menu-toggle {
		top: 12px;
	}
	.header-stack.header-center #Top_bar #menu {
		display: inline-block !important;
	}
	.header-overlay #Top_bar.is-sticky {
		display: none;
	}
	.sticky-dark #Top_bar.is-sticky {
		background: rgba(0, 0, 0, .8);
	}
	.sticky-dark #Top_bar.is-sticky #menu {
		background: none;
	}
	.sticky-dark #Top_bar.is-sticky .menu>li>a {
		color: #fff;
	}
	.sticky-dark #Top_bar.is-sticky .top_bar_right a {
		color: rgba(255, 255, 255, .5);
	}
	.sticky-dark #Top_bar.is-sticky .wpml-languages a.active, .sticky-dark #Top_bar.is-sticky .wpml-languages ul.wpml-lang-dropdown {
		background: rgba(0, 0, 0, 0.3);
		border-color: rgba(0, 0, 0, 0.1);
	}
}
@media only screen and (max-width: 1239px) {
	.header_placeholder {
		height: 0 !important;
	}
	#Top_bar #menu {
		display: none;
		height: auto;
		width: 300px;
		bottom: auto;
		top: 100%;
		right: 1px;
		position: absolute;
		margin: 0px;
	}
	#Top_bar a.responsive-menu-toggle {
		display: block;
		width: 35px;
		height: 35px;
		text-align: center;
		position: absolute;
		top: 28px;
		right: 10px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
	}
	#Top_bar a:hover.responsive-menu-toggle {
		text-decoration: none;
	}
	#Top_bar a.responsive-menu-toggle i {
		font-size: 25px;
		line-height: 35px;
	}
	#Top_bar a.responsive-menu-toggle span {
		float: right;
		padding: 10px 5px;
		line-height: 14px;
	}
	#Top_bar #menu>ul {
		width: 100%;
		float: left;
	}
	#Top_bar #menu ul li {
		width: 100%;
		padding-bottom: 0;
		border-right: 0;
		position: relative;
	}
	#Top_bar #menu ul li a {
		padding: 0 20px;
		margin: 0;
		display: block;
		height: auto;
		line-height: normal;
		border: none;
	}
	#Top_bar #menu ul li a:after {
		display: none;
	}
	#Top_bar #menu ul li a span {
		border: none;
		line-height: 48px;
		display: inline;
		padding: 0;
	}
	#Top_bar #menu ul li a span.description {
		margin: 0 0 0 5px;
	}
	#Top_bar #menu ul li.submenu .menu-toggle {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 48px;
		height: 48px;
		line-height: 48px;
		font-size: 30px;
		text-align: center;
		color: #d6d6d6;
		border-left: 1px solid #eee;
		cursor: pointer;
	}
	#Top_bar #menu ul li.submenu .menu-toggle:after {
		content: "+"
	}
	#Top_bar #menu ul li.hover>.menu-toggle:after {
		content: "-"
	}
	#Top_bar #menu ul li.hover a {
		border-bottom: 0;
	}
	#Top_bar #menu ul li a span:after {
		display: none !important;
	}
	#Top_bar #menu ul.mfn-megamenu li .menu-toggle {
		display: none;
	}
	#Top_bar #menu ul li ul {
		position: relative !important;
		left: 0 !important;
		top: 0;
		padding: 0;
		margin-left: 0 !important;
		width: auto !important;
		background-image: none !important;
		box-shadow: 0 0 0 0 transparent !important;
		-webkit-box-shadow: 0 0 0 0 transparent !important;
	}
	#Top_bar #menu ul li ul li {
		width: 100% !important;
	}
	#Top_bar #menu ul li ul li a {
		padding: 0 20px 0 30px;
	}
	#Top_bar #menu ul li ul li a .menu-arrow {
		display: none;
	}
	#Top_bar #menu ul li ul li a span {
		padding: 0;
	}
	#Top_bar #menu ul li ul li a span:after {
		display: none !important;
	}
	#Top_bar .menu>li>ul.mfn-megamenu a.mfn-megamenu-title {
		text-transform: uppercase;
		font-weight: 400;
	}
	#Top_bar .menu>li>ul.mfn-megamenu>li>ul {
		display: block !important;
		position: inherit;
		left: auto;
		top: auto;
	}
	#Top_bar #menu ul li ul li ul {
		border-left: 0 !important;
		padding: 0;
		top: 0;
	}
	#Top_bar #menu ul li ul li ul li a {
		padding: 0 20px 0 40px;
	}
	.rtl #Top_bar #menu {
		left: 1px;
		right: auto;
	}
	.rtl #Top_bar a.responsive-menu-toggle {
		left: 10px;
		right: auto;
	}
	.rtl #Top_bar #menu ul li.submenu .menu-toggle {
		left: 0;
		right: auto;
		border-left: none;
		border-right: 1px solid #eee;
	}
	.rtl #Top_bar #menu ul li ul {
		left: auto !important;
		right: 0 !important;
	}
	.rtl #Top_bar #menu ul li ul li a {
		padding: 0 30px 0 20px;
	}
	.rtl #Top_bar #menu ul li ul li ul li a {
		padding: 0 40px 0 20px;
	}
	.header-stack #Top_bar {}
	.header-stack .menu_wrapper a.responsive-menu-toggle {
		position: static !important;
		margin: 11px 0;
	}
	.header-stack .menu_wrapper #menu {
		left: 0;
		right: auto;
	}
	.rtl.header-stack #Top_bar #menu {
		left: auto;
		right: 0;
	}
}
#Header_wrapper, #Intro {
	background-color: #dddcd1;
}
#Subheader {
	background-color: rgba(247, 247, 247, 0);
}
.header-classic #Action_bar, .header-plain #Action_bar, .header-stack #Action_bar {
	background-color: #2C2C2C;
}
#Sliding-top {
	background-color: #191f25;
}
#Sliding-top a.sliding-top-control {
	border-right-color: #191f25;
}
#Sliding-top.st-center a.sliding-top-control, #Sliding-top.st-left a.sliding-top-control {
	border-top-color: #191f25;
}
#Footer {
	background-color: #191f25;
}
body, ul.timeline_items, .icon_box a .desc, .icon_box a:hover .desc, .feature_list ul li a, .list_item a, .list_item a:hover, .widget_recent_entries ul li a, .flat_box a, .flat_box a:hover, .story_box .desc, .content_slider.carouselul li a .title, .content_slider.flat.description ul li .desc, .content_slider.flat.description ul li a .desc {
	color: #9d9fa0;
}
.themecolor, .opening_hours .opening_hours_wrapper li span, .fancy_heading_icon .icon_top, .fancy_heading_arrows .icon-right-dir, .fancy_heading_arrows .icon-left-dir, .fancy_heading_line .title, .button-love a.mfn-love, .format-link .post-title .icon-link, .pager-single>span, .pager-single a:hover, .widget_meta ul, .widget_pages ul, .widget_rss ul, .widget_mfn_recent_comments ul li:after, .widget_archive ul, .widget_recent_comments ul li:after, .widget_nav_menu ul, .woocommerce ul.products li.product .price, .shop_slider .shop_slider_ul li .item_wrapper .price, .woocommerce-page ul.products li.product .price, .widget_price_filter .price_label .from, .widget_price_filter .price_label .to, .woocommerce ul.product_list_widget li .quantity .amount, .woocommerce .product div.entry-summary .price, .woocommerce .star-rating span, #Error_404 .error_pic i, .style-simple #Filters .filters_wrapper ul li a:hover, .style-simple #Filters .filters_wrapper ul li.current-cat a, .style-simple .quick_fact .title {
	color: #db9d3a;
}
.themebg, .pager .pages a:hover, .pager .pages a.active, .pager .pages span.page-numbers.current, .pager-single span:after, #comments .commentlist>li .reply a.comment-reply-link, .fixed-nav .arrow, #Filters .filters_wrapper ul li a:hover, #Filters .filters_wrapper ul li.current-cat a, .widget_categories ul, .Recent_posts ul li .desc:after, .Recent_posts ul li .photo .c, .widget_recent_entries ul li:after, .widget_product_categories ul, div.jp-interface, #Top_bar a#header_cart span, .widget_mfn_menu ul li a:hover, .widget_mfn_menu ul li.current-menu-item:not(.current-menu-ancestor)>a, .widget_mfn_menu ul li.current_page_item:not(.current_page_ancestor)>a, .testimonials_slider .slider_images, .testimonials_slider .slider_images a:after, .testimonials_slider .slider_images:before, .slider_pagination a.selected, .slider_pagination a.selected:after, .tp-bullets.simplebullets.round .bullet.selected, .tp-bullets.simplebullets.round .bullet.selected:after, .tparrows.default, .tp-bullets.tp-thumbs .bullet.selected:after, .offer_thumb .slider_pagination a:before, .offer_thumb .slider_pagination a.selected:after, .style-simple .accordion .question:after, .style-simple .faq .question:after, .style-simple .icon_box .desc_wrapper h4:before, .style-simple #Filters .filters_wrapper ul li a:after, .style-simple .article_box .desc_wrapper p:after, .style-simple .sliding_box .desc_wrapper:after, .style-simple .trailer_box:hover .desc, .woocommerce-account table.my_account_orders .order-number a, .portfolio_group.exposure .portfolio-item .desc-inner .line, .style-simple .zoom_box .desc .desc_txt {
	background-color: #db9d3a;
}
.Latest_news ul li .photo, .style-simple .opening_hours .opening_hours_wrapper li label, .style-simple .timeline_items li:hover h3, .style-simple .timeline_items li:nth-child(even):hover h3, .style-simple .timeline_items li:hover .desc, .style-simple .timeline_items li:nth-child(even):hover, .style-simple .offer_thumb .slider_pagination a.selected {
	border-color: #db9d3a;
}
a {
	color: #db9d3a;
}
a:hover {
	color: #c08424;
}
*::-moz-selection {
	background-color: #db9d3a;
}
*::selection {
	background-color: #db9d3a;
}
.blockquote p.author span, .counter .desc_wrapper .title, .article_box .desc_wrapper p, .team .desc_wrapper p.subtitle, .pricing-box .plan-header p.subtitle, .pricing-box .plan-header .price sup.period, .chart_box p, .fancy_heading .inside, .fancy_heading_line .slogan, .post-meta, .post-meta a, .post-footer, .post-footer a span.label, .pager .pages a, .button-love a .label, .pager-single a, #comments .commentlist>li .comment-author .says, .fixed-nav .desc .date, .filters_buttons li.label, .Recent_posts ul li a .desc .date, .widget_recent_entries ul li .post-date, .tp_recent_tweets .twitter_time, .widget_price_filter .price_label, .shop-filters .woocommerce-result-count, .woocommerce ul.product_list_widget li .quantity, .widget_shopping_cart ul.product_list_widget li dl, .product_meta .posted_in, .woocommerce .shop_table .product-name .variation>dd, .shipping-calculator-button:after, .shop_slider .shop_slider_ul li .item_wrapper .price del, .testimonials_slider .testimonials_slider_ul li .author span, .testimonials_slider .testimonials_slider_ul li .author span a, .Latest_news ul li .desc_footer {
	color: #a8a8a8;
}
h1, h1 a, h1 a:hover, .text-logo #logo {
	color: #181e22;
}
h2, h2 a, h2 a:hover {
	color: #181e22;
}
h3, h3 a, h3 a:hover {
	color: #181e22;
}
h4, h4 a, h4 a:hover, .style-simple .sliding_box .desc_wrapper h4 {
	color: #181e22;
}
h5, h5 a, h5 a:hover {
	color: #181e22;
}
h6, h6 a, h6 a:hover, a.content_link .title {
	color: #181e22;
}
.dropcap, .highlight:not(.highlight_image) {
	background-color: #db9d3a;
}
a.button, a.tp-button {
	background-color: #D6D6D6;
	color: #747474;
}
.button-stroke a.button, .button-stroke a.button .button_icon i, .button-stroke a.tp-button {
	border-color: #D6D6D6;
	color: #747474;
}
.button-stroke a:hover.button, .button-stroke a:hover.tp-button {
	background-color: #D6D6D6 !important;
	color: #fff;
}
a.button_theme, a.tp-button.button_theme, button, input[type="submit"], input[type="reset"], input[type="button"] {
	background-color: #db9d3a;
	color: #fff;
}
.button-stroke a.button.button_theme:not(.action_button), .button-stroke a.button.button_theme:not(.action_button), .button-stroke a.button.button_theme .button_icon i, .button-stroke a.tp-button.button_theme, .button-stroke button, .button-stroke input[type="submit"], .button-stroke input[type="reset"], .button-stroke input[type="button"] {
	border-color: #db9d3a;
	color: #db9d3a !important;
}
.button-stroke a.button.button_theme:hover, .button-stroke a.tp-button.button_theme:hover, .button-stroke button:hover, .button-stroke input[type="submit"]:hover, .button-stroke input[type="reset"]:hover, .button-stroke input[type="button"]:hover {
	background-color: #db9d3a !important;
	color: #fff !important;
}
a.mfn-link {
	color: #656B6F;
}
a.mfn-link-2 span, a:hover.mfn-link-2 span:before, a.hover.mfn-link-2 span:before, a.mfn-link-5 span, a.mfn-link-8:after, a.mfn-link-8:before {
	background: #db9d3a;
}
a:hover.mfn-link {
	color: #db9d3a;
}
a.mfn-link-2 span:before, a:hover.mfn-link-4:before, a:hover.mfn-link-4:after, a.hover.mfn-link-4:before, a.hover.mfn-link-4:after, a.mfn-link-5:before, a.mfn-link-7:after, a.mfn-link-7:before {
	background: #c08424;
}
a.mfn-link-6:before {
	border-bottom-color: #c08424;
}
.woocommerce a.button, .woocommerce .quantity input.plus, .woocommerce .quantity input.minus {
	background-color: #D6D6D6 !important;
	color: #747474 !important;
}
.woocommerce a.button_theme, .woocommerce a.checkout-button, .woocommerce button.button, .woocommerce .button.add_to_cart_button, .woocommerce .button.product_type_external, .woocommerce input[type="submit"], .woocommerce input[type="reset"], .woocommerce input[type="button"], .button-stroke .woocommerce a.checkout-button {
	background-color: #db9d3a !important;
	color: #fff !important;
}
.column_column ul, .column_column ol, .the_content_wrapper ul, .the_content_wrapper ol {
	color: #737E86;
}
.hr_color, .hr_color hr, .hr_dots span {
	color: #db9d3a;
	background: #db9d3a;
}
.hr_zigzag i {
	color: #db9d3a;
}
.highlight-left:after, .highlight-right:after {
	background: #db9d3a;
}
@media only screen and (max-width: 767px) {
	.highlight-left .wrap:first-child, .highlight-right .wrap:last-child {
		background: #db9d3a;
	}
}
#Header .top_bar_left, .header-classic #Top_bar, .header-plain #Top_bar, .header-stack #Top_bar, .header-split #Top_bar, .header-fixed #Top_bar, .header-below #Top_bar, #Header_creative, #Top_bar #menu {
	background-color: #191f25;
}
#Top_bar .top_bar_right:before {
	background-color: #e3e3e3;
}
#Header .top_bar_right {
	background-color: #f5f5f5;
}
#Top_bar .top_bar_right a {
	color: #444444;
}
#Top_bar .menu>li>a {
	color: #C6CAD0;
}
#Top_bar .menu>li.current-menu-item>a, #Top_bar .menu>li.current_page_item>a, #Top_bar .menu>li.current-menu-parent>a, #Top_bar .menu>li.current-page-parent>a, #Top_bar .menu>li.current-menu-ancestor>a, #Top_bar .menu>li.current-page-ancestor>a, #Top_bar .menu>li.current_page_ancestor>a, #Top_bar .menu>li.hover>a {
	color: #fff;
}
#Top_bar .menu>li a:after {
	background: #fff;
}
.menu-highlight #Top_bar #menu>ul>li.current-menu-item>a, .menu-highlight #Top_bar #menu>ul>li.current_page_item>a, .menu-highlight #Top_bar #menu>ul>li.current-menu-parent>a, .menu-highlight #Top_bar #menu>ul>li.current-page-parent>a, .menu-highlight #Top_bar #menu>ul>li.current-menu-ancestor>a, .menu-highlight #Top_bar #menu>ul>li.current-page-ancestor>a, .menu-highlight #Top_bar #menu>ul>li.current_page_ancestor>a, .menu-highlight #Top_bar #menu>ul>li.hover>a {
	background: #F2F2F2;
}
.menu-arrow-bottom #Top_bar .menu>li>a:after {
	border-bottom-color: #fff;
}
.menu-arrow-top #Top_bar .menu>li>a:after {
	border-top-color: #fff;
}
.header-plain #Top_bar .menu>li.current-menu-item>a, .header-plain #Top_bar .menu>li.current_page_item>a, .header-plain #Top_bar .menu>li.current-menu-parent>a, .header-plain #Top_bar .menu>li.current-page-parent>a, .header-plain #Top_bar .menu>li.current-menu-ancestor>a, .header-plain #Top_bar .menu>li.current-page-ancestor>a, .header-plain #Top_bar .menu>li.current_page_ancestor>a, .header-plain #Top_bar .menu>li.hover>a, .header-plain #Top_bar a:hover#header_cart, .header-plain #Top_bar a:hover#search_button, .header-plain #Top_bar .wpml-languages:hover, .header-plain #Top_bar .wpml-languages ul.wpml-lang-dropdown {
	background: #F2F2F2;
	color: #fff;
}
.header-plain #Top_bar, .header-plain #Top_bar .menu>li>a span:not(.description), .header-plain #Top_bar a#header_cart, .header-plain #Top_bar a#search_button, .header-plain #Top_bar .wpml-languages, .header-plain #Top_bar a.button.action_button {
	border-color: #F2F2F2;
}
#Top_bar .menu>li ul {
	background-color: #F2F2F2;
}
#Top_bar .menu>li ul li a {
	color: #5f5f5f;
}
#Top_bar .menu>li ul li a:hover, #Top_bar .menu>li ul li.hover>a {
	color: #2e2e2e;
}
#Top_bar .search_wrapper {
	background: #db9d3a;
}
.overlay-menu-toggle {
	color: $gray !important;
}
#Overlay {
	background: rgba(219, 157, 58, 0.95);
}
#overlay-menu ul li a, .header-overlay .overlay-menu-toggle.focus {
	color: #fff;
}
#overlay-menu ul li.current-menu-item>a, #overlay-menu ul li.current_page_item>a, #overlay-menu ul li.current-menu-parent>a, #overlay-menu ul li.current-page-parent>a, #overlay-menu ul li.current-menu-ancestor>a, #overlay-menu ul li.current-page-ancestor>a, #overlay-menu ul li.current_page_ancestor>a {
	color: #B1DCFB;
}
#Top_bar .responsive-menu-toggle {
	color: #db9d3a;
}
#Subheader .title {
	color: #fff;
}
#Subheader ul.breadcrumbs li, #Subheader ul.breadcrumbs li a {
	color: rgba(255, 255, 255, 0.6);
}
#Footer, #Footer .widget_recent_entries ul li a {
	color: #cccccc;
}
#Footer a {
	color: #909CA9;
}
#Footer a:hover {
	color: #AFBAC5;
}
#Footer h1, #Footer h1 a, #Footer h1 a:hover, #Footer h2, #Footer h2 a, #Footer h2 a:hover, #Footer h3, #Footer h3 a, #Footer h3 a:hover, #Footer h4, #Footer h4 a, #Footer h4 a:hover, #Footer h5, #Footer h5 a, #Footer h5 a:hover, #Footer h6, #Footer h6 a, #Footer h6 a:hover {
	color: #fff;
}
#Footer .themecolor, #Footer .widget_meta ul, #Footer .widget_pages ul, #Footer .widget_rss ul, #Footer .widget_mfn_recent_comments ul li:after, #Footer .widget_archive ul, #Footer .widget_recent_comments ul li:after, #Footer .widget_nav_menu ul, #Footer .widget_price_filter .price_label .from, #Footer .widget_price_filter .price_label .to, #Footer .star-rating span {
	color: #909CA9;
}
#Footer .themebg, #Footer .widget_categories ul, #Footer .Recent_posts ul li .desc:after, #Footer .Recent_posts ul li .photo .c, #Footer .widget_recent_entries ul li:after, #Footer .widget_mfn_menu ul li a:hover, #Footer .widget_product_categories ul {
	background-color: #909CA9;
}
#Footer .Recent_posts ul li a .desc .date, #Footer .widget_recent_entries ul li .post-date, #Footer .tp_recent_tweets .twitter_time, #Footer .widget_price_filter .price_label, #Footer .shop-filters .woocommerce-result-count, #Footer ul.product_list_widget li .quantity, #Footer .widget_shopping_cart ul.product_list_widget li dl {
	color: #a8a8a8;
}
#Sliding-top, #Sliding-top .widget_recent_entries ul li a {
	color: #cccccc;
}
#Sliding-top a {
	color: #909CA9;
}
#Sliding-top a:hover {
	color: #AFBAC5;
}
#Sliding-top h1, #Sliding-top h1 a, #Sliding-top h1 a:hover, #Sliding-top h2, #Sliding-top h2 a, #Sliding-top h2 a:hover, #Sliding-top h3, #Sliding-top h3 a, #Sliding-top h3 a:hover, #Sliding-top h4, #Sliding-top h4 a, #Sliding-top h4 a:hover, #Sliding-top h5, #Sliding-top h5 a, #Sliding-top h5 a:hover, #Sliding-top h6, #Sliding-top h6 a, #Sliding-top h6 a:hover {
	color: #fff;
}
#Sliding-top .themecolor, #Sliding-top .widget_meta ul, #Sliding-top .widget_pages ul, #Sliding-top .widget_rss ul, #Sliding-top .widget_mfn_recent_comments ul li:after, #Sliding-top .widget_archive ul, #Sliding-top .widget_recent_comments ul li:after, #Sliding-top .widget_nav_menu ul, #Sliding-top .widget_price_filter .price_label .from, #Sliding-top .widget_price_filter .price_label .to, #Sliding-top .star-rating span {
	color: #909CA9;
}
#Sliding-top .themebg, #Sliding-top .widget_categories ul, #Sliding-top .Recent_posts ul li .desc:after, #Sliding-top .Recent_posts ul li .photo .c, #Sliding-top .widget_recent_entries ul li:after, #Sliding-top .widget_mfn_menu ul li a:hover, #Sliding-top .widget_product_categories ul {
	background-color: #909CA9;
}
#Sliding-top .Recent_posts ul li a .desc .date, #Sliding-top .widget_recent_entries ul li .post-date, #Sliding-top .tp_recent_tweets .twitter_time, #Sliding-top .widget_price_filter .price_label, #Sliding-top .shop-filters .woocommerce-result-count, #Sliding-top ul.product_list_widget li .quantity, #Sliding-top .widget_shopping_cart ul.product_list_widget li dl {
	color: #a8a8a8;
}
blockquote, blockquote a, blockquote a:hover {
	color: #444444;
}
.image_frame .image_wrapper .image_links, .portfolio_group.masonry-hover .portfolio-item .masonry-hover-wrapper .hover-desc {
	background: rgba(219, 157, 58, 0.8);
}
.masonry.tiles .post-item .post-desc-wrapper .post-desc .post-title:after, .masonry.tiles .post-item.no-img, .masonry.tiles .post-item.format-quote {
	background: #db9d3a;
}
.image_frame .image_wrapper .image_links a {
	color: #fff;
}
.image_frame .image_wrapper .image_links a:hover {
	background: #fff;
	color: #db9d3a;
}
.sliding_box .desc_wrapper {
	background: #db9d3a;
}
.sliding_box .desc_wrapper:after {
	border-bottom-color: #db9d3a;
}
.counter .icon_wrapper i {
	color: #db9d3a;
}
.quick_fact .number-wrapper {
	color: #db9d3a;
}
.progress_bars .bars_list li .bar .progress {
	background-color: #db9d3a;
}
a:hover.icon_bar {
	color: #db9d3a !important;
}
a.content_link, a:hover.content_link {
	color: #db9d3a;
}
a.content_link:before {
	border-bottom-color: #db9d3a;
}
a.content_link:after {
	border-color: #db9d3a;
}
.get_in_touch, .infobox {
	background-color: #db9d3a;
}
.column_map .google-map-contact-wrapper .get_in_touch:after {
	border-top-color: #db9d3a;
}
.timeline_items li h3:before, .timeline_items:after, .timeline .post-item:before {
	border-color: #db9d3a;
}
.how_it_works .image .number {
	background: #db9d3a;
}
.trailer_box .desc .subtitle {
	background-color: #db9d3a;
}
.icon_box .icon_wrapper, .icon_box a .icon_wrapper, .style-simple .icon_box:hover .icon_wrapper {
	color: #db9d3a;
}
.icon_box:hover .icon_wrapper:before, .icon_box a:hover .icon_wrapper:before {
	background-color: #db9d3a;
}
ul.clients.clients_tiles li .client_wrapper:hover:before {
	background: #db9d3a;
}
ul.clients.clients_tiles li .client_wrapper:after {
	border-bottom-color: #db9d3a;
}
.list_item.lists_1 .list_left {
	background-color: #db9d3a;
}
.list_item .list_left {
	color: #db9d3a;
}
.feature_list ul li .icon i {
	color: #db9d3a;
}
.feature_list ul li:hover, .feature_list ul li:hover a {
	background: #db9d3a;
}
.ui-tabs .ui-tabs-nav li.ui-state-active a, .accordion .question.active .title>.acc-icon-plus, .accordion .question.active .title>.acc-icon-minus, .faq .question.active .title>.acc-icon-plus, .faq .question.active .title, .accordion .question.active .title {
	color: #db9d3a;
}
.ui-tabs .ui-tabs-nav li.ui-state-active a:after {
	background: #db9d3a;
}
body.table-hover:not(.woocommerce-page) table tr:hover td {
	background: #db9d3a;
}
.pricing-box .plan-header .price sup.currency, .pricing-box .plan-header .price>span {
	color: #db9d3a;
}
.pricing-box .plan-inside ul li .yes {
	background: #db9d3a;
}
.pricing-box-box.pricing-box-featured {
	background: #db9d3a;
}
input[type="date"], input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], select, textarea, .woocommerce .quantity input.qty {
	color: #909395;
	background-color: rgba(220, 223, 225, 1);
	border-color: #dcdfe1;
}
input[type="date"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="url"]:focus, select:focus, textarea:focus {
	color: #797d80;
	background-color: rgba(212, 216, 218, 1) !important;
	border-color: #d4d8da;
}
.woocommerce span.onsale, .shop_slider .shop_slider_ul li .item_wrapper span.onsale {
	border-top-color: #db9d3a !important;
}
.woocommerce .widget_price_filter .ui-slider .ui-slider-handle {
	border-color: #db9d3a !important;
}
@media only screen and (min-width: 768px) {
	.header-semi #Top_bar:not(.is-sticky) {
		background-color: rgba(25, 31, 37, 0.8);
	}
}
@media only screen and (max-width: 767px) {
	#Top_bar, #Action_bar {
		background: #191f25 !important;
	}
}
html {
	background-color: #f0f1f2;
}
#Wrapper, #Content {
	background-color: #f0f1f2;
}
body:not(.template-slider) #Header_wrapper {
	background-image: url("../images/home_lifestyle_subheader.jpg");
}
body, button, span.date_label, .timeline_items li h3 span, input[type="submit"], input[type="reset"], input[type="button"], input[type="text"], input[type="password"], input[type="tel"], input[type="email"], textarea, select, .offer_li .title h3 {
	font-family: $pfont;
	font-weight: 400;
}
#menu>ul>li>a, .action_button, #overlay-menu ul li a {
	font-family: $hfont;
	font-weight: 400;
}
#Subheader .title {
	font-family: $hfont;
	font-weight: 400;
}
h1, .text-logo #logo {
	font-family: $hfont;
	font-weight: 300;
}
h2 {
	font-family: $hfont;
	font-weight: 300;
}
h3 {
	font-family: $hfont;
	font-weight: 300;
}
h4 {
	font-family: $hfont;
	font-weight: 300;
}
h5 {
	font-family: $hfont;
	font-weight: 700;
}
h6 {
	font-family: $hfont;
	font-weight: 400;
}
blockquote {
	font-family: $pfont;
}
.chart_box .chart .num, .counter .desc_wrapper .number-wrapper, .how_it_works .image .number, .pricing-box .plan-header .price, .quick_fact .number-wrapper, .woocommerce .product div.entry-summary .price {
	font-family: $pfont;
}
body {
	font-size: 14px;
	line-height: 22px;
}
#menu>ul>li>a, .action_button {
	font-size: 18px;
}
#Subheader .title {
	font-size: 42px;
	line-height: 42px;
}
h1, .text-logo #logo {
	font-size: 70px;
	line-height: 70px;
}
h2 {
	font-size: 24px;
	line-height: 24px;
}
h3 {
	font-size: 42px;
	line-height: 44px;
}
h4 {
	font-size: 30px;
	line-height: 34px;
}
h5 {
	font-size: 17px;
	line-height: 22px;
}
h6 {
	font-size: 14px;
	line-height: 21px;
}
#Intro .intro-title {
	font-size: 70px;
	line-height: 70px;
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
	body {
		font-size: 13px;
		line-height: 20px;
	}
	#menu>ul>li>a {
		font-size: 15px;
	}
	#Subheader .title {
		font-size: 36px;
		line-height: 36px;
	}
	h1, .text-logo #logo {
		font-size: 60px;
		line-height: 60px;
	}
	h2 {
		font-size: 20px;
		line-height: 20px;
	}
	h3 {
		font-size: 36px;
		line-height: 38px;
	}
	h4 {
		font-size: 26px;
		line-height: 30px;
	}
	h5 {
		font-size: 14px;
		line-height: 18px;
	}
	h6 {
		font-size: 13px;
		line-height: 19px;
	}
	#Intro .intro-title {
		font-size: 60px;
		line-height: 60px;
	}
	blockquote {
		font-size: 15px;
	}
	.chart_box .chart .num {
		font-size: 45px;
		line-height: 45px;
	}
	.counter .desc_wrapper .number-wrapper {
		font-size: 45px;
		line-height: 45px;
	}
	.counter .desc_wrapper .title {
		font-size: 14px;
		line-height: 18px;
	}
	.faq .question .title {
		font-size: 14px;
	}
	.fancy_heading .title {
		font-size: 38px;
		line-height: 38px;
	}
	.offer .offer_li .desc_wrapper .title h3 {
		font-size: 32px;
		line-height: 32px;
	}
	.offer_thumb_ul li.offer_thumb_li .desc_wrapper .title h3 {
		font-size: 32px;
		line-height: 32px;
	}
	.pricing-box .plan-header h2 {
		font-size: 27px;
		line-height: 27px;
	}
	.pricing-box .plan-header .price>span {
		font-size: 40px;
		line-height: 40px;
	}
	.pricing-box .plan-header .price sup.currency {
		font-size: 18px;
		line-height: 18px;
	}
	.pricing-box .plan-header .price sup.period {
		font-size: 14px;
		line-height: 14px;
	}
	.quick_fact .number {
		font-size: 80px;
		line-height: 80px;
	}
	.trailer_box .desc h2 {
		font-size: 27px;
		line-height: 27px;
	}
	.column_column ul, .column_column ol, .column_visual ul, .column_visual ol, .mfn-acc ul, .mfn-acc ol, .ui-tabs-panel ul, .ui-tabs-panel ol, .the_content_wrapper ul, .the_content_wrapper ol {
		font-size: 14px;
		line-height: 19px;
	}
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
	body {
		font-size: 13px;
		line-height: 20px;
	}
	#menu>ul>li>a {
		font-size: 14px;
	}
	#Subheader .title {
		font-size: 32px;
		line-height: 32px;
	}
	h1, .text-logo #logo {
		font-size: 53px;
		line-height: 53px;
	}
	h2 {
		font-size: 18px;
		line-height: 18px;
	}
	h3 {
		font-size: 32px;
		line-height: 34px;
	}
	h4 {
		font-size: 23px;
		line-height: 27px;
	}
	h5 {
		font-size: 13px;
		line-height: 17px;
	}
	h6 {
		font-size: 13px;
		line-height: 18px;
	}
	#Intro .intro-title {
		font-size: 53px;
		line-height: 53px;
	}
	blockquote {
		font-size: 14px;
	}
	.chart_box .chart .num {
		font-size: 40px;
		line-height: 40px;
	}
	.counter .desc_wrapper .number-wrapper {
		font-size: 40px;
		line-height: 40px;
	}
	.counter .desc_wrapper .title {
		font-size: 13px;
		line-height: 16px;
	}
	.faq .question .title {
		font-size: 13px;
	}
	.fancy_heading .title {
		font-size: 34px;
		line-height: 34px;
	}
	.offer .offer_li .desc_wrapper .title h3 {
		font-size: 28px;
		line-height: 28px;
	}
	.offer_thumb_ul li.offer_thumb_li .desc_wrapper .title h3 {
		font-size: 28px;
		line-height: 28px;
	}
	.pricing-box .plan-header h2 {
		font-size: 24px;
		line-height: 24px;
	}
	.pricing-box .plan-header .price>span {
		font-size: 34px;
		line-height: 34px;
	}
	.pricing-box .plan-header .price sup.currency {
		font-size: 16px;
		line-height: 16px;
	}
	.pricing-box .plan-header .price sup.period {
		font-size: 13px;
		line-height: 13px;
	}
	.quick_fact .number {
		font-size: 70px;
		line-height: 70px;
	}
	.trailer_box .desc h2 {
		font-size: 24px;
		line-height: 24px;
	}
	.column_column ul, .column_column ol, .column_visual ul, .column_visual ol, .mfn-acc ul, .mfn-acc ol, .ui-tabs-panel ul, .ui-tabs-panel ol, .the_content_wrapper ul, .the_content_wrapper ol {
		font-size: 13px;
		line-height: 16px;
	}
}
@media only screen and (max-width: 479px) {
	body {
		font-size: 13px;
		line-height: 20px;
	}
	#menu>ul>li>a {
		font-size: 13px;
	}
	#Subheader .title {
		font-size: 25px;
		line-height: 25px;
	}
	h1, .text-logo #logo {
		font-size: 42px;
		line-height: 42px;
	}
	h2 {
		font-size: 14px;
		line-height: 14px;
	}
	h3 {
		font-size: 25px;
		line-height: 27px;
	}
	h4 {
		font-size: 18px;
		line-height: 21px;
	}
	h5 {
		font-size: 13px;
		line-height: 16px;
	}
	h6 {
		font-size: 13px;
		line-height: 17px;
	}
	#Intro .intro-title {
		font-size: 42px;
		line-height: 42px;
	}
	blockquote {
		font-size: 13px;
	}
	.chart_box .chart .num {
		font-size: 35px;
		line-height: 35px;
	}
	.counter .desc_wrapper .number-wrapper {
		font-size: 35px;
		line-height: 35px;
	}
	.counter .desc_wrapper .title {
		font-size: 13px;
		line-height: 26px;
	}
	.faq .question .title {
		font-size: 13px;
	}
	.fancy_heading .title {
		font-size: 30px;
		line-height: 30px;
	}
	.offer .offer_li .desc_wrapper .title h3 {
		font-size: 26px;
		line-height: 26px;
	}
	.offer_thumb_ul li.offer_thumb_li .desc_wrapper .title h3 {
		font-size: 26px;
		line-height: 26px;
	}
	.pricing-box .plan-header h2 {
		font-size: 21px;
		line-height: 21px;
	}
	.pricing-box .plan-header .price>span {
		font-size: 32px;
		line-height: 32px;
	}
	.pricing-box .plan-header .price sup.currency {
		font-size: 14px;
		line-height: 14px;
	}
	.pricing-box .plan-header .price sup.period {
		font-size: 13px;
		line-height: 13px;
	}
	.quick_fact .number {
		font-size: 60px;
		line-height: 60px;
	}
	.trailer_box .desc h2 {
		font-size: 21px;
		line-height: 21px;
	}
	.column_column ul, .column_column ol, .column_visual ul, .column_visual ol, .mfn-acc ul, .mfn-acc ol, .ui-tabs-panel ul, .ui-tabs-panel ol, .the_content_wrapper ul, .the_content_wrapper ol {
		font-size: 13px;
		line-height: 16px;
	}
}
.with_aside .sidebar.columns {
	width: 23%;
}
.with_aside .sections_group {
	width: 77%;
}
.aside_both .sidebar.columns {
	width: 18%;
}
.aside_both .sidebar.sidebar-1 {
	margin-left: -82%;
}
.aside_both .sections_group {
	width: 64%;
	margin-left: 18%;
}
#Top_bar #logo, .header-plain #Top_bar #logo, .header-transparent #Top_bar #logo {
	height: 60px;
	line-height: 60px;
	padding: 0px 0;
}
#Top_bar .menu>li>a {
	padding: 0px 0;
}
.menu-highlight:not(.header-creative) #Top_bar .menu>li>a {
	margin: 5px 0;
}
.header-plain:not(.menu-highlight) #Top_bar .menu>li>a span:not(.description) {
	line-height: 60px;
}
#Top_bar .top_bar_right, .header-plain #Top_bar .top_bar_right {
	height: 60px;
}
#Top_bar .top_bar_right_wrapper {
	top: 10px;
}
.header-plain #Top_bar a#header_cart, .header-plain #Top_bar a#search_button, .header-plain #Top_bar .wpml-languages, .header-plain #Top_bar a.button.action_button {
	line-height: 60px;
}
#Top_bar a.responsive-menu-toggle, .header-plain #Top_bar a.responsive-menu-toggle, .header-transparent #Top_bar a.responsive-menu-toggle {
	top: 13px;
}
.twentytwenty-before-label::before {
	content: "Before";
}
.twentytwenty-after-label::before {
	content: "After";
}
h1 {
	font-weight: 400;
}
h2 {
	font-weight: 400;
	letter-spacing: 1px;
}
h3 {
	font-weight: 400;
}
h4 {
	font-weight: 400;
}
h5 {
	font-weight: 400;
}
h6 {
	font-weight: 400;
}

/* Widget */
.widget h4 {
	font-size: 22px;
}

/* Post related */
.post-related .desc h4 {
	font-size: 20px;
	line-height: 25px;
}

/*
 |--------------------------------------------------------------------------
 |   Demo Styles
 |
 |--------------------------------------------------------------------------
 |
 |
 |
 */
body.contact #Content, body.content_no_padding #Content {
	padding-top: 0 !important;
}



/* ------------------------------------------- *
 * font awesome
 * ------------------------------------------- */
.the-icons.span3 {
	float: left;
	width: 25%;
	line-height: 25px;
	padding-bottom: 10px;
	position: relative;
}
.the-icons.span3 [class^="icon-"] {
	color: #3E3E3E;
	font-size: 20px;
	position: absolute;
	top: 0;
	left: 0;
	cursor: default;
}
.the-icons.span3 [class^="icon-"]:hover {
	font-size: 50px;
	background: #fff;
	padding: 10px;
	display: block;
	text-align: center;
	width: 70px;
	height: 50px;
	border: 1px solid #eee;
	top: -25px;
	left: -32px;
	z-index: 2;
}
.the-icons.span3 .i-name {
	padding-left: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
	.the-icons.span3 {
		width: 33.333%;
	}
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
	.the-icons.span3 {
		width: 50%;
	}
}
@media only screen and (max-width: 479px) {
	.the-icons.span3 {
		width: 100%;
	}
}

/* ------------------------------------------- *
 * demo
 * ------------------------------------------- */

/* sections */
.parallax-video .content_video iframe {
	border: 35px solid #efefef;
	-webkit-border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.section-border-top {
	border-top: 1px solid rgba0, 0, 0, .08;
}
.notable_works .items_group {
	background: #eaeae8;
}
.notable_works .items_group .fancy_heading h2 {
	margin-bottom: 0;
	padding-top: 40px;
}

/* custom inline add */
.hrmargin_0, p.hrmargin_0, hr.hrmargin_0, div.hrmargin_0, h2.hrmargin_0, h3.hrmargin_0, h4.hrmargin_0, h5.hrmargin_0, h6.hrmargin_0 {
	margin: 0;
}
.hrmargin_b_60, p.hrmargin_b_60, hr.hrmargin_b_60, div.hrmargin_b_60, h2.hrmargin_b_60, h3.hrmargin_b_60, h4.hrmargin_b_60, h5.hrmargin_b_60, h6.hrmargin_b_60 {
	margin: 0 auto 60px;
}
.hrmargin_b_30, p.hrmargin_b_30, hr.hrmargin_b_30, div.hrmargin_b_30, h2.hrmargin_b_30, h3.hrmargin_b_30, h4.hrmargin_b_30, h5.hrmargin_b_30, h6.hrmargin_b_30 {
	margin-bottom: 30px;
}
.hrmargin_b_1, p.hrmargin_b_1, hr.hrmargin_b_1, div.hrmargin_b_1, h2.hrmargin_b_1, h3.hrmargin_b_1, h4.hrmargin_b_1, h5.hrmargin_b_1, h6.hrmargin_b_1 {
	margin: 0 auto 1px;
}
.hrmargin_b_10, p.hrmargin_b_10, hr.hrmargin_b_10, div.hrmargin_b_10, h2.hrmargin_b_10, h3.hrmargin_b_10, h4.hrmargin_b_10, h5.hrmargin_b_10, h6.hrmargin_b_10 {
	margin: 0 auto 10px;
}
.hrmargin_b_7, p.hrmargin_b_7, hr.hrmargin_b_7, div.hrmargin_b_7, h2.hrmargin_b_7, h3.hrmargin_b_7, h4.hrmargin_b_7, h5.hrmargin_b_7, h6.hrmargin_b_7 {
	margin-bottom: 7px;
}
.hrmargin_b_40, p.hrmargin_b_40, hr.hrmargin_b_40, div.hrmargin_b_40, h2.hrmargin_b_40, h3.hrmargin_b_40, h4.hrmargin_b_40, h5.hrmargin_b_40, h6.hrmargin_b_40 {
	margin: 0 auto 40px;
}
.hrmargin_b_40, p.hrmargin_b_20, hr.hrmargin_b_20, div.hrmargin_b_20, h2.hrmargin_b_20, h3.hrmargin_b_20, h4.hrmargin_b_20, h5.hrmargin_b_20, h6.hrmargin_b_20 {
	margin: 0 auto 20px;
}
.hrmargin_b_25, p.hrmargin_b_25, hr.hrmargin_b_25, div.hrmargin_b_25, h2.hrmargin_b_25, h3.hrmargin_b_25, h4.hrmargin_b_25, h5.hrmargin_b_25, h6.hrmargin_b_25 {
	margin: 0 auto 25px;
}
.hrmargin_b_5, p.hrmargin_b_5, hr.hrmargin_b_5, div.hrmargin_b_5, h2.hrmargin_b_5, h3.hrmargin_b_5, h4.hrmargin_b_5, h5.hrmargin_b_5, h6.hrmargin_b_5 {
	margin-bottom: 5px;
}
.hrmargin_b_4, p.hrmargin_b_4, hr.hrmargin_b_4, div.hrmargin_b_4, h2.hrmargin_b_4, h3.hrmargin_b_4, h4.hrmargin_b_4, h5.hrmargin_b_4, h6.hrmargin_b_4 {
	margin-bottom: 4px;
}
.hrmargin_l_26, p.hrmargin_l_26, hr.hrmargin_l_26, div.hrmargin_l_26, h2.hrmargin_l_26, h3.hrmargin_l_26, h4.hrmargin_l_26, h5.hrmargin_l_26, h6.hrmargin_l_26 {
	margin-left: 26px;
}
.hrmargin_b_30, p.hrmargin_b_30, hr.hrmargin_b_30, div.hrmargin_b_30, h2.hrmargin_b_30, h3.hrmargin_b_30, h4.hrmargin_b_30, h5.hrmargin_b_30, h6.hrmargin_b_30 {
	margin: 0 auto 30px;
}
.hrmargin_b_15, p.hrmargin_b_15, hr.hrmargin_b_15, div.hrmargin_b_15, h2.hrmargin_b_15, h3.hrmargin_b_15, h4.hrmargin_b_15, h5.hrmargin_b_15, h6.hrmargin_b_15 {
	margin: 0 auto 15px;
}
.flv_disp_none, div.flv_disp_none, p.flv_disp_none {
	display: none;
}
.flv_viz_hid, div.flv_viz_hid, p.flv_viz_hid {
	visibility: hidden !important;
}
.flv_clear_both, div.flv_clear_both, p.flv_clear_both {
	clear: both
}
.sections_style_0, div.sections_style_0 {
	padding-top: 0px;
	padding-bottom: 0px;
}
.sections_style_1, div.sections_style_1 {
	margin: 0px auto;
	padding: 0px;
	margin-top: 0px;
	margin-bottom: 0px;
}
.sections_style_2, div.sections_style_2 {
	padding-top: 60px;
	padding-bottom: 0px;
}
.sections_style_3, div.sections_style_3 {
	padding-top: 60px;
	padding-bottom: 20px;
}
.sections_style_4, div.sections_style_4 {
	padding-top: 40px;
	padding-bottom: 0px;
}
.sections_style_5, div.sections_style_5 {
	padding-top: 70px;
	padding-bottom: 0px;
}
.flv_span_12, span.flv_span_12, div.flv_span_12 {
	padding-top: 12px;
	display: block;
}
.flv_list, ul.flv_list {
	float: left;
	width: 46%;
	margin-left: 4%;
	list-style: square;
	line-height: 30px;
}
.flv_list_1, li.flv_list_1 {
	display: inline-block;
	margin: 0 10px;
}
.flv_list_ul_46, ul.flv_list_ul_46 {
	float: left;
	width: 46%;
	margin-left: 4%;
	list-style: square;
	line-height: 27px;
}
.flv_list_ul_10, ul.flv_list_ul_10 {
	display: block;
	margin: 0 0 10px 0;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(255, 255, 255, .1);
}
i.flv_icon_color {
	color: #afbcda;
}
ul.flv_line_height_32 {
	line-height: 32px;
}
p.flv_align_right, div.flv_align_right {
	text-align: right;
}
.flv_float_right, div.flv_float_right {
	float: right;
}