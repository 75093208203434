﻿/**
    QUANTITY TRIGGER

    Component to modify product quantity in the cart

    [1]. We use flexbox here since overall browser support is about 95% worldwide.
    [2]. Also, as fallback, we 'float' the columns. If your browser doesn't support flexbox, columns will be
         properly positionned even if the columns won't have the same height (graceful degradation).
    [3]. You can choose your own symbol (or even custom icon font) for add and remove product.
*/

/*------------------------------------*\
    LOCAL VARIABLES
\*------------------------------------*/

// [3]
$_qt-text-add: "+";
$_qt-text-remove: "-";


/*------------------------------------*\
    STYLE
\*------------------------------------*/

// [1]
.snip-quantity-trigger {
    @include font-bold;
    align-items: center;
    color: palette(quantity-trigger, color);
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.snip-quantity-trigger__text {
    // [2]
    float: left;
    padding: 0 .313em;
}

.snip-quantity-trigger__btn--duplicate {
    background-color: palette(quantity-trigger, background--hover);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: palette(quantity-trigger, color);
    font-size: fontsize(quantity-trigger, x-large);
    font-weight: 400;
    margin-top: -1.6px;
    line-height: 1.6;
    height: 1em;
    width: 1em;

    &:hover {
      background-color: palette(button, background--highlight-hover);
    }

    &:before {
        display: block;
        height: 1em;
        width: 1em;
    }
}

.snip-quantity-trigger__btn {
    // [2]
    background-color: palette(quantity-trigger, background);
    border-radius: 30px;
    color: inherit;
    float: left;
    font-size: fontsize(quantity-trigger, large);
    font-weight: 400;
    height: 1em;
    width: 1em;

    &:hover {
        background-color: palette(quantity-trigger, background--hover);
        color: palette(quantity-trigger, color--hover);
    }

    &:before {
        display: block;
        height: 1em;
        width: 1em;
    }
}

.snip-quantity-trigger__btn--add {
    &:before {
        // [3]
        content: $_qt-text-add;
        line-height: 1em;
    }
}

.snip-quantity-trigger__btn--minus {
    &:before {
        // [3]
        content: $_qt-text-remove;
        line-height: 14px;
    }
}

.snip-quantity-trigger__btn--disabled {
    background-color: palette(quantity-trigger, background--disabled);
    color: palette(quantity-trigger, color--disabled);
    cursor: default;

    &:hover {
        background-color: palette(quantity-trigger, background--disabled);
    }
}
