﻿/**
    ERROR

    Errors text, except for form fields errors.
*/

/*------------------------------------*\
    LOCAL VARIABLES
\*------------------------------------*/


/*------------------------------------*\
    STYLE
\*------------------------------------*/

.snip-error {
    padding: 2em 15%;
}

.snip-error__text {
    color: palette(error, color);
    font-size: fontsize(error, base);
    margin-bottom: 1em;
}

.snip-error__text--centered {
    text-align: center;
}

.snip-error__title {
    @include font-bold;
    color: palette(error, text-color);
    margin: 1em 0;
    text-align: center;
    text-transform: uppercase;
}

.snip-error__list {
    font-size: fontsize(error, base);
    list-style: disc inside;
    margin: 2em 0;
}

.snip-error__list-item {
    padding: .25em 0;
}

.snip-error__textarea {
    width: 100%;
}

.snip-error__feedback {
    font-size: fontsize(error, base);
    text-align: center;
    text-transform: uppercase;
}