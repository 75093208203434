$brown: #5e452b;
$gray: #e8e3db;
$orange: #db9d3a;

$hfont: 'Oswald', sans-serif;
$pfont: 'PT Serif', serif;

$logo_height: 120px;

$article-font-size: 19px;
$article-line-height: 1.6em;