/**
    COLOR

    Where all color's variables are defined

    Thanks to SASS Map, we can define all colors at one place.
    We also respect our BEM like notation and files architecture.
*/

$palettes: (
    action: (
        link: #999
    ),
    button: (
        background: $gray,
        background--highlight: $orange,
        background--hover: darken($gray, 10%),
        background--danger: #f10,
        background--danger-hover: darken(#f10, 10%),
        background--highlight-hover: darken($orange, 10%),
        color: $brown,
        color--highlight: #fff,
    ),
    error: (
        color: #f10,
        text-color: #333,
        text-color--invert: #fff
    ),
    badge: (
        background--success: #76d443,
        background--error: #f10,
        background--warn: #efe778,
        text--success: #333,
        text--error: #fff,
        text--warn: #333
    ),
    flash: (
        background--error: #f10,
        background--success: #76d443,
        background--warning: #efe778,
        background--confirm: #222,
        border: #333,
        color: #333,
        color--invert: #fff
    ),
    footer: (
        background: $gray,
        color: $brown,
        color--highlight: #999
    ),
    form: (
        background: #fff,
        border: #eee,
        border--focus: #333,
        color: #555,
        color--highlight: #333,
        label-color: #999
    ),
    header: (
        background: #191f25,
        background--highlight: #111,
        color: $gray,
        label-color: #999
    ),
    layout: (
        background: #fff,
        border: #eee,
        close-color: $brown,
        color: #999,
        content-background: #f8f8f8,
        content-border: #bbb,
        loading-color: #333
    ),
    loader: (
        background: #222,
        background--highlight: #efe778
    ),
    product: (
        background: #f8f8f8,
        border: #eee,
        color--error: $brown,
        color--highlight: #333
    ),
    quantity-trigger: (
        background: $gray,
        background--hover: $brown,
        background--disabled: #eee,
        color: $brown,
        color--hover: $gray,
        color--disabled: #bbb
    ),
    static: (
        background--highlight: #f8f8f8,
        border: #eee,
        color--highlight: #333,
        color--link: #999
    ),
    step: (
        background: $gray,
        background--active: #fff,
        border: #bbb,
        color: $brown,
        color--active: $brown
    ),
    table: (
        background--highlight: #f8f8f8,
        border: #eee,
        color--highlight: #333,
        color--success: #76d443
    ),
    tooltip: (
        background: #ccc,
        border: #ccc transparent,
        label: #555,
        text: #111
    )
);