@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?k2i272');
  src:  url('fonts/icomoon.eot?k2i272#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?k2i272') format('truetype'),
    url('fonts/icomoon.woff?k2i272') format('woff'),
    url('fonts/icomoon.svg?k2i272#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="moon-"], [class*=" moon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.moon-newsletter-subscribe:before {
  content: "\e911";
}
.moon-money-estimate:before {
  content: "\e920";
  color: #525d7d;
}
.moon-buy:before {
  content: "\e921";
}
.moon-house-price-tag:before {
  content: "\e91f";
}
.moon-floor-plans:before {
  content: "\e918";
}
.moon-house-dimensions:before {
  content: "\e919";
}
.moon-simple-plan:before {
  content: "\e91a";
}
.moon-constructor:before {
  content: "\e91b";
}
.moon-plan-concept:before {
  content: "\e91c";
}
.moon-tinyhouse:before {
  content: "\e913";
}
.moon-car-place:before {
  content: "\e915";
}
.moon-icon_instagram:before {
  content: "\e90d";
  color: #e8e3db;
}
.moon-arrow:before {
  content: "\e900";
  color: #5e452b;
}
.moon-calendar:before {
  content: "\e904";
}
.moon-category:before {
  content: "\e905";
  color: #5e452b;
}
.moon-comment:before {
  content: "\e906";
  color: #5e452b;
}
.moon-heart_empty:before {
  content: "\e907";
  color: #5e452b;
}
.moon-heart_full:before {
  content: "\e908";
  color: #5e452b;
}
.moon-sort:before {
  content: "\e909";
  color: #5e452b;
}
.moon-timer:before {
  content: "\e90a";
  color: #5e452b;
}
.moon-pinterest_2:before {
  content: "\e90b";
  color: #5e452b;
}
.moon-facebook_2:before {
  content: "\e90c";
  color: #e8e3db;
}
.moon-facebook:before {
  content: "\e90e";
  color: #e8e3db;
}
.moon-pinterest:before {
  content: "\e90f";
  color: #e8e3db;
}
.moon-share:before {
  content: "\e910";
  color: #e8e3db;
}
