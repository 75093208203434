﻿/**
    MEDIA QUERIES

    Where all media queries' variables are defined
*/

$mq-phone: "only screen and (max-width: 26em)"; // under 416px
$mq-small: "only screen and (max-width: 48em)"; // under 768px
$mq-medium: "only screen and (min-width: 48em)"; // 768px
$mq-large: "only screen and (min-width: 64em)"; // 1024px
